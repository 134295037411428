import { SocialLinks } from "./SocialLink";

export function NetworkCard({
  name,
  location,
  photo,
  showSocial = false,
  social_media,
}) {
  return (
    <div className="relative aspect-[4/5] overflow-hidden rounded-[2rem]">
      <div
        className="absolute inset-0 bg-contain bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${photo})` }}
      />
      {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#000]/30 to-[#048642]/80" /> */}

      <div className="absolute inset-0 bg-gradient-to-b from-[#000]/10 via-black/20 to-green-600" />

      <div className="absolute bottom-0 left-0 p-4 text-white">
        <h2 className="text-xl font-bold">{name}</h2>
        <p className="text-sm">{location}</p>
        {showSocial ? <SocialLinks className="mt-2" social_media /> : null}
      </div>
    </div>
  );
}
