import moment from "moment";
import React from "react";

const BlogDescription = ({ data }) => {
  return (
    <div className="bg-white relative overflow-hidden flex flex-col justify-center py-20">
      <div className="sm-container flex flex-col gap-3">
        <div>
          <span className="font-bold">
            {moment(data?.created_at).format("MMM DD, YYYY")}
          </span>
        </div>
        <img
          src={data?.photo}
          alt=""
          className="w-full h-96 object-cover object-center"
        />
        <div dangerouslySetInnerHTML={{ __html: data?.description }} />
      </div>
    </div>
  );
};

export default BlogDescription;
