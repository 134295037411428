import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { FaBars, FaChevronDown, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useCommonContext } from "../hooks/useCommonContext";
import { menuItems } from "../routes/menu";

const NavMenu = () => {
  const navRef = useRef(null);
  const { categories } = useCommonContext();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [menu, setMenu] = useState(menuItems);

  const dropdownRefs = useRef({});

  const handleMouseEnter = (index) => {
    if (dropdownRefs.current[index]) {
      gsap.to(dropdownRefs.current[index], {
        duration: 0.3,
        opacity: 1,
        y: 0,
        display: "block",
      });
    }
  };

  const handleMouseLeave = (index) => {
    if (dropdownRefs.current[index]) {
      gsap.to(dropdownRefs.current[index], {
        duration: 0.3,
        opacity: 0,
        y: -10,
        display: "none",
      });
    }
  };

  // TODO: calculate the menu

  useEffect(() => {
    const galleryId = categories?.data?.find((c) => c?.slug === "gallery")?.id;
    // const resourceId = categories?.data?.find(
    //   (c) => c?.slug === "resources"
    // )?.id;

    const galleryCategories = categories?.data?.filter?.(
      (cat) => cat?.category_id === galleryId
    );

    // const resourcesCategories = categories?.data?.filter?.(
    //   (cat) => cat?.category_id === resourceId
    // );

    setMenu((prev) =>
      prev?.map((m) => {
        if (m?.name === "Gallery") {
          return {
            ...m,
            dropdownItems: galleryCategories?.map((gc) => ({
              name: gc?.name,
              to: `/gallery/${gc?.slug}`,
            })),
          };
        }
        // if (m?.name === "Resources") {
        //   return {
        //     ...m,
        //     dropdownItems: resourcesCategories?.map((rc) => ({
        //       name: rc?.name,
        //       to: `/resources/${rc?.slug}`,
        //     })),
        //   };
        // }

        return m;
      })
    );
  }, [categories]);

  useGSAP(
    () => {
      gsap.from(".navItem", {
        y: -40,
        duration: 0.5,
        stagger: 0.05,
      });
    },
    { scope: navRef }
  );

  return (
    <>
      {/* Toggle button for mobile view */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="lg:hidden p-2"
        aria-label="Toggle menu"
      >
        {isOpen ? (
          <FaTimes className="w-6 h-6" />
        ) : (
          <FaBars className="w-6 h-6" />
        )}
      </button>

      {/* Main navigation */}
      <nav
        ref={navRef}
        className={`${isOpen ? "block" : "hidden"} lg:block w-full`}
      >
        <ul className="flex font-medium w-full justify-between">
          {menu?.map((item, index) => (
            <li
              key={item.to}
              className="relative group"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <Link
                to={item.to}
                className={`flex navItem items-center h-[4rem] px-4 hover:text-orange-400 transition-colors text-nowrap ${
                  location.pathname === item.to ||
                  (item.hasDropdown &&
                    location.pathname.startsWith(`/${item.name.toLowerCase()}`))
                    ? "text-orange-400"
                    : "text-white"
                }`}
              >
                {item.name}
                {item.hasDropdown && (
                  <FaChevronDown className="ml-1 w-3 h-3 transition-transform duration-300 group-hover:rotate-180" />
                )}
              </Link>

              {/* Dropdown menu */}
              {item?.hasDropdown && (
                <ul
                  ref={(el) => (dropdownRefs.current[index] = el)}
                  className="absolute left-0 top-full text-black bg-lightBg opacity-0 transform -translate-y-2 hidden group-hover:block"
                  style={{ zIndex: 99999, minWidth: "100%" }}
                >
                  {item?.dropdownItems?.map((subItem) => (
                    <li key={subItem.name}>
                      <Link
                        to={subItem.to}
                        className="block px-4 py-2 hover:bg-orange-400 hover:text-white text-nowrap"
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default NavMenu;
