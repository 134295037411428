import gsap from "gsap";
import { useEffect, useRef } from "react";
import { IoCloseCircle } from "react-icons/io5";

export function Dialog({ open, onOpenChange, children, className }) {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (open) {
      gsap.fromTo(
        dialogRef.current,
        { opacity: 0, scale: 0.9 },
        { opacity: 1, scale: 1, duration: 0.5, ease: "power3.out" }
      );
    } else if (dialogRef.current) {
      gsap.to(dialogRef.current, {
        opacity: 0,
        scale: 0.9,
        duration: 0.5,
        ease: "power3.in",
        onComplete: () => {
          if (onOpenChange) onOpenChange(false);
        },
      });
    }
  }, [open, onOpenChange]);

  return (
    open && (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
        // onClick={() => onOpenChange(false)}
      >
        <div
          ref={dialogRef}
          className={`relative bg-black shadow-xl ${className} h-fit`}
          onClick={(e) => e.stopPropagation()} // Prevent click propagation to the overlay
        >
          <>{children}</>
          <button
            className="absolute top-5 right-5"
            onClick={() => onOpenChange(false)}
          >
            <IoCloseCircle size={40} color="white" />
          </button>
        </div>
      </div>
    )
  );
}
