import React from "react";
import { useParams } from "react-router-dom";
import Error404Modern from "../error/404-modern";
import Events from "../programs/events/Events";
import ClimateAction from "./ClimateAction";
import {
  default as Empowerment,
  default as NetworkingAdvocacy,
} from "./Empowerment";
import ICD from "./ICD";
import Partnership from "./Partnership";
import Trainings from "./trainings/Trainings";

const ProgramIndex = () => {
  const { category } = useParams();

  const renderItem = (item) => {
    switch (item) {
      case "events":
        return <Events />;

      case "trainings":
        return <Trainings />;

      case "integrated-community-developmemnt":
        return <ICD />;

      case "networking-advocacy":
        return <NetworkingAdvocacy />;

      case "empowerment-and-capacity-building":
        return <Empowerment />;

      case "climate-action":
        return <ClimateAction />;

      case "global-and-regional-partnership":
        return <Partnership />;

      default:
        return <Error404Modern />;
    }
  };

  return renderItem(category);
};

export default ProgramIndex;
