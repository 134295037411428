// src/Gallery.jsx
import React from "react";
import PageHeader from "../components/PageHeader";
import GallerySection from "../sections/gallery/GallerySection";

const Gallery = () => {
  return (
    <div className="bg-lightBg">
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-gallerybanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Gallery"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Gallery",
              to: "/gallery",
            },
          ]}
        />
      </div>
      <GallerySection />
    </div>
  );
};

export default Gallery;
