import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";
import NormalHeader from "../../components/NormalHeader";

gsap.registerPlugin(ScrollTrigger);

const BranchSection = () => {
  return (
    <div className="bg-white flex flex-col items-center justify-center relative text-center py-20 overflow-hidden">
      <div className="lg-container z-10">
        <NormalHeader subTitle={"Our branches"} title="Our Branches" />
        <img src="/branchmap.png" alt="" />
      </div>
    </div>
  );
};

export default BranchSection;
