import React from "react";
import PageHeader from "../../components/PageHeader";

const Empowerment = () => {
  return (
    <>
      <div className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center">
        <div className="absolute inset-0 bg-empBg bg-center bg-cover bg-no-repeat" />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Empowerment and Capacity Building"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },
            {
              name: "Empowerment and Capacity Building",
              to: "/services/empowerment-and-capacity-building",
            },
          ]}
        />
      </div>

      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container flex flex-col gap-5">
          <section className="text-lg leading-relaxed">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">
              Empowerment and capacity building
            </h1>
            <ul className="space-y-6 list-decimal list-inside">
              <li className="font-bold">Training</li>
              <p>
                We provide long and short term trainings affiliated to CTEVT.
                Some trainings are provided each year in regular basis and some
                trainings are conducted based on the demands and necessities. We
                also provide residential training facilities targeting to the
                rural youths from across Nepal.
              </p>
              <ul className="space-y-3">
                <li className="font-bold ml-4">
                  1.1 Standardized training program
                </li>

                <ul className="space-y-2 ml-8 list-disc list-inside">
                  <li>Training on Psychosocial Counselling</li>
                  <li>Montessori Training</li>
                  <li>Child Protection Worker Training</li>
                  <li>Enterprise Development Facilitator Training</li>
                  <li>Para Processional Social Worker Training program</li>
                </ul>
              </ul>

              <ul className="space-y-3">
                <li className="font-bold ml-4">
                  1.2 Short term training program
                </li>

                <ul className="space-y-2 ml-8 list-disc list-inside">
                  <li>Project Cycle Management</li>
                  <li>Finance Management</li>
                  <li>Participatory Action Research</li>
                  <li>Planning & Strategy Development</li>
                  <li>Advocacy & Networking</li>
                  <li>Social Audit</li>
                  <li>Life Skills Development</li>
                  <li>Organization Management</li>
                  <li>Cooperative Management</li>
                  <li>PRA (Participatory Rural Appraisal)</li>
                  <li>Disaster Management</li>
                  <li>Report Writing</li>
                  <li>Training of Trainers (ToT)</li>
                  <li>Gender & Social Inclusion</li>
                  <li>Baseline Survey</li>
                  <li>Result Base Management (RBM)</li>
                  <li>Proposal Writing</li>
                  <li>Training on Agroecology</li>
                  <li>Other trainings on demands</li>
                  <li>Empowerment of youth, children, women and peasants</li>
                </ul>
              </ul>

              <li className="font-bold">Women&apos;s empowerment</li>
              <p>
                Empowering women is essential for sustainable development and
                societal progress. By involving women in savings and credit
                mobilization, they gain access to financial resources that
                foster economic independence and enhance their ability to
                contribute to their families and communities. These financial
                platforms not only address immediate needs but also encourage
                long-term planning and resilience. Women are also at the
                forefront of business-oriented organic farming, adopting
                sustainable agricultural practices that promote environmental
                conservation and generate income. Through income-generating
                activities such as crafting and small-scale trade, women develop
                entrepreneurial skills, enabling them to create stable
                livelihoods. Furthermore, capacity-building trainings and
                awareness campaigns equip women with the skills, knowledge, and
                confidence to address critical issues like health, education,
                and gender equality.
              </p>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default Empowerment;
