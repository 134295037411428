import React from "react";
import PageHeader from "../../components/PageHeader";

const PrivacyPolicy = () => {
  const privacyPolicySections = [
    {
      title: "Overview",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          The Social Work Institute (SWI) is committed to protecting the privacy
          of our users and participants. This Privacy Policy explains how we
          collect, use, and disclose your personal information.
        </ul>
      ),
    },
    {
      title: "Information We Collect",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          <li className="text-lg flex-1">Name </li>
          <li className="text-lg flex-1">
            Contact information (email address, phone number)
          </li>
          <li className="text-lg flex-1">
            Demographic information (age, gender)
          </li>
          <li className="text-lg flex-1">
            Information related to your participation in SWI programs
            (workshops, training, projects)
          </li>
          <li className="text-lg flex-1">
            Any other information you choose to share
          </li>
        </ul>
      ),
    },
    {
      title: "Use of Information",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          <li className="text-lg flex-1">
            Provide you with information about SWI programs and services
          </li>
          <li className="text-lg flex-1">
            Administer your participation in programs and projects
          </li>
          <li className="text-lg flex-1">Improve our programs and services</li>
          <li className="text-lg flex-1">
            Send you communications related to SWI activities and events
          </li>
          <li className="text-lg flex-1">
            Comply with legal and regulatory requirements
          </li>
        </ul>
      ),
    },
    {
      title: "Disclosure of Information",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          We will not share your personal information with any third party
          without your consent, except:
          <li className="text-lg flex-1">
            To service providers who help us operate our website or programs
            (these providers are bound by contractual obligations to keep your
            information confidential) In the event we are required to do so by
            law
          </li>
        </ul>
      ),
    },
    {
      title: "Data Security",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          We take reasonable steps to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. However,
          no website or internet transmission is completely secure.
        </ul>
      ),
    },
    {
      title: "Your Rights",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          You have the right to access, correct, or delete your personal
          information. You can also opt out of receiving communications from us.
          To exercise these rights, please contact us.
        </ul>
      ),
    },
    {
      title: "Changes to this Privacy Policy",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
        </ul>
      ),
    },
  ];

  return (
    <div>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-aboutbanner bg-top bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Privacy Poilicy"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "About Us",
              to: "/about",
            },
          ]}
        />
      </div>

      <div className="lg-container py-20">
        <h2 className="text-3xl font-bold text-gray-800 text-center">
          Privacy Policy
        </h2>

        {privacyPolicySections.map((section, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl font-semibold text-gray-700 mb-4 border-b pb-2">
              {section.title}
            </h3>
            {section.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
