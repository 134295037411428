import React from "react";
import { useParams } from "react-router-dom";
import Error404Modern from "../error/404-modern";
import CareerPage from "./careers";
import NoticesPage from "./notices";

const AnnouncementsIndex = () => {
  const { category } = useParams();

  const renderItem = (item) => {
    switch (item) {
      case "careers":
        return <CareerPage />;

      case "notices":
        return <NoticesPage />;

      default:
        return <Error404Modern />;
    }
  };

  return renderItem(category);
};

export default AnnouncementsIndex;
