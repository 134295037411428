import React from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import NetworkSection from "../../sections/networks/NetworkSection";
import Alumni from "./Alumni";
import Partner from "./Partner";

const Network = () => {
  const { category } = useParams();

  return (
    <div>
      {category === "alumni" ? (
        <Alumni />
      ) : category === "partners" ? (
        <Partner />
      ) : (
        <div>
          <div
            // ref={bannerRef}
            className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
          >
            <div
              // ref={backgroundRef}
              className="absolute inset-0 bg-empBg bg-center bg-cover bg-no-repeat"
            />
            {/* Black overlay with gradient */}
            <div
              className="absolute inset-0"
              style={{
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
              }}
            />
            <PageHeader
              title="Networks"
              breadcrumbs={[
                {
                  name: "Home",
                  to: "/",
                },

                {
                  name: "Networks",
                  to: "/network/networks",
                },
              ]}
            />
          </div>
          <NetworkSection />
        </div>
      )}
    </div>
  );
};

export default Network;
