import React from "react";
import PageHeader from "../../components/PageHeader";

const Partnership = () => {
  return (
    <>
      <div className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center">
        <div className="absolute inset-0 bg-empBg bg-center bg-cover bg-no-repeat" />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Global and Regional Partnership"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },
            {
              name: "Global and Regional Partnership",
              to: "/services/global-and-regional-partnership",
            },
          ]}
        />
      </div>

      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container flex flex-col">
          <section>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">
              Local, regional and global partnership
            </h1>

            <ul className="space-y-3 list-decimal list-inside">
              <li className="font-bold">Youth networks</li>

              <div>
                <p>
                  We are working on all the three ecological zones of Nepal. To
                  unite youth to empower themselves, we are forming
                  community-based youth groups and wards and municipal-level
                  networks. They work on the expansion of youth groups,
                  establish trial farms, promote and practice Agroecology and
                  take part in national, regional and international forum,
                  networks and platforms. To enhance their knowledge and build
                  their capacity, we provide them capacity building trainings
                  and workshops.
                </p>
                <p>
                  Currently, there are three provincial level youth networks and
                  a national level youth network in the execution. In addition,
                  there are around 40 youth groups are functioning in our
                  project areas.
                </p>
              </div>

              <li className="font-bold">Regional forums and networks</li>

              <div>
                <p>
                  We are a part of different regional and global forums and
                  networks to work collaboratively in the common agenda. We
                  encourage young people, farmers, women to connect with the
                  regional and global network and speak about their experience
                  and learn from each other. This will provide opportunity Youth
                  leaders, Farmer leaders, women and marginalized group more
                  confident and learn from the other experience.
                </p>
                <p>
                  At present SWI is connect with different networks like, APEX,
                  Agroecology collation, Mountain partnership. The network
                  allows us to work together in the common issues.
                </p>
              </div>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default Partnership;
