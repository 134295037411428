import PageHeader from "../../components/PageHeader";
import PartnerSection from "../../sections/networks/PartnerSection";

export default function Partner() {
  return (
    <div>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-empBg bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Partner"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Partner",
              to: "/about",
            },
          ]}
        />
      </div>
      <PartnerSection />
    </div>
  );
}
