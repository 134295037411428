import React from "react";
import { useParams } from "react-router-dom";
import Error404Modern from "../error/404-modern";
import Excursion from "./excursion";
import KnowledgeExchange from "./knowledge-exhange";
import SeminarWorkshopTraining from "./seminar-workshop-training";
import TrainingFacilities from "./training-facilities";

const ServicesIndex = () => {
  const { category } = useParams();

  const renderItem = (item) => {
    switch (item) {
      case "training-facilities":
        return <TrainingFacilities />;

      case "seminar-workshop-training":
        return <SeminarWorkshopTraining />;

      case "knowledge-exchange-and-other-facilities":
        return <KnowledgeExchange />;

      case "excursion":
        return <Excursion />;

      default:
        return <Error404Modern />;
    }
  };

  return renderItem(category);
};

export default ServicesIndex;
