import { useAnimate } from "framer-motion";
import React, { useEffect } from "react";
import { BiChevronUp } from "react-icons/bi";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Layout = ({ children }) => {
  const navigate = useAnimate();
  const params = useParams();
  useEffect(() => {
    window.scrollTo({
      top: 0, // Vertical scroll position
      left: 0, // Horizontal scroll position
      behavior: "smooth", // Optional: "auto" (default) or "smooth"
    });
  }, [navigate, params]);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0, // Vertical scroll position
      left: 0, // Horizontal scroll position
      behavior: "smooth", // Optional: "auto" (default) or "smooth"
    });
  };

  return (
    <div className="relative">
      <Header />
      {children}
      <Footer />

      <button
        onClick={handleScrollTop}
        className="fixed flex items-center animate-bounce justify-center bottom-5 right-5 z-50 bg-primary text-white w-10 h-10 rounded-full"
      >
        <BiChevronUp size={30} />
      </button>
    </div>
  );
};

export default Layout;
