import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { cn } from "../../lib/utils";

export const StickyScroll = ({ content, contentClassName }) => {
  const [activeCard, setActiveCard] = React.useState(0);
  const [contentHeights, setContentHeights] = React.useState([]);
  const ref = useRef(null);
  const contentRefs = React.useRef([]);

  const { scrollYProgress } = useScroll({
    container: ref,
    offset: ["start start", "end start"],
  });

  useEffect(() => {
    const measureHeights = () => {
      const heights = contentRefs.current.map((ref) => ref?.offsetHeight || 0);
      setContentHeights(heights);
    };

    measureHeights();
    window.addEventListener("resize", measureHeights);

    return () => window.removeEventListener("resize", measureHeights);
  }, [content]);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const totalHeight = contentHeights.reduce((sum, height) => sum + height, 0);
    const heightRatios = contentHeights.map((height) => height / totalHeight);

    let accumulatedRatio = 0;
    const cardsBreakpoints = heightRatios.map((ratio) => {
      const breakpoint = accumulatedRatio;
      accumulatedRatio += ratio;
      return breakpoint;
    });

    const closestBreakpointIndex = cardsBreakpoints.reduce(
      (acc, breakpoint, index) => {
        const distance = Math.abs(latest - breakpoint);
        if (distance < Math.abs(latest - cardsBreakpoints[acc])) {
          return index;
        }
        return acc;
      },
      0
    );

    setActiveCard(closestBreakpointIndex);
  });

  return (
    <motion.div
      className="h-[30rem] sticky-scroll overflow-y-auto flex justify-center relative space-x-10 rounded-md"
      ref={ref}
    >
      <div className="relative flex items-start px-4 flex-1">
        <div className="">
          {content.map((item, index) => (
            <div
              key={index}
              className="my-20"
              ref={(el) => (contentRefs.current[index] = el)}
            >
              <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: activeCard === index ? 1 : 0.3 }}
                className="text-2xl font-bold text-primary font-title uppercase"
              >
                {item.title}
              </motion.h2>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: activeCard === index ? 1 : 0.3 }}
                className="text-lg text-muted-foreground mt-5"
              >
                {item.description}
              </motion.p>
            </div>
          ))}
        </div>
      </div>
      <div
        className={cn(
          "hidden lg:block bg-card sticky top-0 overflow-hidden flex-1",
          contentClassName
        )}
      >
        {content[activeCard].content ?? null}
      </div>
    </motion.div>
  );
};
