import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";
import BlogCard from "../../components/BlogCard";
import { useCommonContext } from "../../hooks/useCommonContext";

const BlogSection = () => {
  const { categories } = useCommonContext();

  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);

        // Step 2: Fetch clients for each categoryId
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/blogs`,
          {
            params: {
              category_id: categories?.data?.find(
                (cat) => cat?.slug === "blogs"
              )?.id,
            },
          }
        );

        setBlogs(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [categories?.data]);

  return (
    <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
      <div className="lg-container">
        {/* <NormalHeader
          animate={false}
          title="Read and explore your knowledge through our news"
          subTitle="Our blogs"
        /> */}

        {loading && <Loading />}
        {error && <p className="text-red-500">{error}</p>}

        {!loading &&
          !error &&
          (blogs?.data?.[0]?.data?.length ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              {blogs?.data?.[0]?.data?.map((data, index) => (
                <BlogCard key={index} data={data} />
              ))}
            </div>
          ) : (
            <div className="col-span-full text-center py-12">
              <h3 className="text-2xl font-semibold text-gray-600">
                No Blogs Currently
              </h3>
              <p className="text-gray-500 mt-4">
                Check back later for new blogs.
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BlogSection;
