/* eslint-disable jsx-a11y/iframe-has-title */
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiCalendar, BiMapPin } from "react-icons/bi";
import { BsMailbox, BsPhone } from "react-icons/bs";
import { HiX } from "react-icons/hi";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Loading from "../../../common/Loading";

export default function TrainingDetail() {
  const [trainingDetail, setTrainingDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [registrationForm, setRegistrationForm] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    education: "",
    reason_to_attend: "",
    event_id: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isRegistering, setIsRegistering] = useState(false);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Full Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    phone: Yup.string().required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    education: Yup.string().required("Education details are required"),
    reason_to_attend: Yup.string()
      .required("Reason for attending is required")
      .min(10, "Please provide a more detailed reason"),
  });

  const { slug } = useParams();

  useEffect(() => {
    const fetchSingleEvent = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/events/${slug}`
        );

        setTrainingDetail(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSingleEvent();
  }, [slug]);

  useEffect(() => {
    // Set event_id when event details are loaded
    if (trainingDetail) {
      setRegistrationForm((prev) => ({
        ...prev,
        event_id: trainingDetail?.id,
      }));
    }
  }, [trainingDetail]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegistrationForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitRegistration = async (e) => {
    e.preventDefault();

    try {
      setIsRegistering(true);

      // Validate the form
      await validationSchema.validate(registrationForm, { abortEarly: false });

      // Create FormData for submission
      const formData = new FormData();
      formData.append("name", registrationForm.name);
      formData.append("email", registrationForm.email);
      formData.append("phone", registrationForm.phone);
      formData.append("address", registrationForm.address);
      formData.append("education", registrationForm.education);
      formData.append("reason_to_attend", registrationForm.reason_to_attend);
      formData.append("event_id", registrationForm.event_id);

      // Submit registration
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/participants`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Reset form and close modal
      setRegistrationForm({
        name: "",
        email: "",
        phone: "",
        address: "",
        education: "",
        reason_to_attend: "",
        event_id: null,
      });
      setIsRegistrationModalOpen(false);
      setFormErrors({});

      // Show success message
      alert("Registration submitted successfully!");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Handle validation errors
        const errors = {};
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
        setFormErrors(errors);
      } else {
        // Handle submission errors
        console.error("Registration submission error:", error);
        alert(
          error?.response?.data?.message ||
            "Failed to submit registration. Please try again."
        );
      }
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="h-screen">
          <Loading />
        </div>
      )}
      {error && <p className="text-red-500">{error}</p>}

      {!loading && !error && (
        <div className="lg-container px-4 py-20">
          <div className="grid md:grid-cols-3 gap-6">
            <div className="md:col-span-2">
              <h1 className="text-4xl font-bold mb-4">
                {trainingDetail?.name}
              </h1>
              <div className="relative mb-6 overflow-hidden">
                <img
                  src={trainingDetail?.featured_img}
                  alt={trainingDetail?.name}
                  className="object-cover w-full aspect-video"
                />
              </div>
              <div className="space-y-6">
                <p
                  className="text-muted-foreground"
                  dangerouslySetInnerHTML={{
                    __html: trainingDetail?.description,
                  }}
                />
              </div>
            </div>
            <div className="md:sticky md:top-4 self-start space-y-6">
              <section className="bg-muted rounded-lg p-4">
                <h2 className="text-2xl font-semibold mb-4">Organizer</h2>
                <div>
                  <p className="font-medium">SWI Nepal</p>
                </div>
              </section>
              <section className="bg-muted rounded-lg p-4">
                <h2 className="text-2xl font-semibold mb-4">Event Details</h2>
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <BiCalendar className="h-5 w-5 text-muted-foreground" />
                    <div>
                      <p className="font-medium">
                        {moment(trainingDetail?.date).format("DD MMMM, YYYY")}
                      </p>
                      <p className="text-sm text-muted-foreground">
                        {moment(trainingDetail?.time, "HH:mm:ss").format(
                          "hh:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <BiMapPin className="h-5 w-5 text-muted-foreground" />
                    <div>
                      <p className="font-medium">{trainingDetail?.location}</p>
                    </div>
                  </div>
                  {trainingDetail?.map && (
                    <div className="mt-4 w-full">
                      <iframe
                        src={trainingDetail?.map}
                        width="100%"
                        height="250"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="rounded-lg shadow-md"
                      />
                    </div>
                  )}
                </div>
              </section>
              <section className="bg-muted rounded-lg p-4">
                <h2 className="text-2xl font-semibold mb-4">
                  Contact Information
                </h2>
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <BsPhone className="h-5 w-5 text-muted-foreground" />
                    <p>+977-1-5185315</p>
                  </div>
                  <div className="flex items-center space-x-3">
                    <BsMailbox className="h-5 w-5 text-muted-foreground" />
                    <p>info@swi-nepal.org</p>
                  </div>
                </div>
              </section>
              <button
                disabled={
                  trainingDetail?.participants_count ===
                  trainingDetail?.occupancy
                }
                className={`w-full bg-primary text-white p-2 rounded-lg ${
                  trainingDetail?.participants_count ===
                  trainingDetail?.occupancy
                    ? "opacity-50"
                    : ""
                }`}
                onClick={() => setIsRegistrationModalOpen(true)}
              >
                {trainingDetail?.participants_count ===
                trainingDetail?.occupancy
                  ? "Already Full"
                  : "Join Now"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Registration Modal */}
      {isRegistrationModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto py-10">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl mx-4 relative max-h-[90vh] overflow-y-auto">
            <button
              onClick={() => setIsRegistrationModalOpen(false)}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 z-10"
            >
              <HiX size={24} />
            </button>

            <div className="p-6">
              <h2 className="text-2xl font-bold mb-6 text-center">
                Register for {trainingDetail?.name}
              </h2>

              <form onSubmit={handleSubmitRegistration} className="space-y-4">
                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={registrationForm.name}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                        ${
                          formErrors.name
                            ? "border-red-500 focus:ring-red-500"
                            : "border-gray-300 focus:ring-blue-500"
                        }`}
                    />
                    {formErrors.name && (
                      <p className="text-red-500 text-xs mt-1">
                        {formErrors.name}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={registrationForm.email}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                        ${
                          formErrors.email
                            ? "border-red-500 focus:ring-red-500"
                            : "border-gray-300 focus:ring-blue-500"
                        }`}
                    />
                    {formErrors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {formErrors.email}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={registrationForm.phone}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                        ${
                          formErrors.phone
                            ? "border-red-500 focus:ring-red-500"
                            : "border-gray-300 focus:ring-blue-500"
                        }`}
                    />
                    {formErrors.phone && (
                      <p className="text-red-500 text-xs mt-1">
                        {formErrors.phone}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={registrationForm.address}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                        ${
                          formErrors.address
                            ? "border-red-500 focus:ring-red-500"
                            : "border-gray-300 focus:ring-blue-500"
                        }`}
                    />
                    {formErrors.address && (
                      <p className="text-red-500 text-xs mt-1">
                        {formErrors.address}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Education <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="education"
                      value={registrationForm.education}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                        ${
                          formErrors.education
                            ? "border-red-500 focus:ring-red-500"
                            : "border-gray-300 focus:ring-blue-500"
                        }`}
                    />
                    {formErrors.education && (
                      <p className="text-red-500 text-xs mt-1">
                        {formErrors.education}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Reason for Attending <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    rows={5}
                    name="reason_to_attend"
                    value={registrationForm.reason_to_attend}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.reason_to_attend
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-blue-500"
                      }`}
                  />
                  {formErrors.reason_to_attend && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.reason_to_attend}
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className={`w-full bg-primary text-white py-2 rounded-md hover:bg-primary transition ${
                    isRegistering ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isRegistering}
                >
                  {isRegistering ? (
                    <div className="flex justify-center">
                      <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full text-white"></div>
                      <span className="ml-2">Registering...</span>
                    </div>
                  ) : (
                    "Register"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
