export const menuItems = [
  { name: "Home", to: "/" },
  { name: "About Us", to: "/about" },
  {
    name: "Services",
    // to: "/services",
    hasDropdown: true,
    dropdownItems: [
      { name: "Training Facilities", to: "/services/training-facilities" },
      // {
      //   name: "Seminar Workshop Training",
      //   to: "/services/seminar-workshop-training",
      // },
      {
        name: "Knowledge exchange and other facilities",
        to: "/services/knowledge-exchange-and-other-facilities",
      },
      // { name: "Excursion", to: "/services/excursion" },
    ],
  },
  {
    name: "Network",
    // to: "/network",
    hasDropdown: true,
    dropdownItems: [
      { name: "Alumni", to: "/network/alumni" },
      { name: "Network", to: "/network/networks" },
      { name: "Partners", to: "/network/partners" },
    ],
  },
  {
    name: "Programs",
    // to: "/programs",
    hasDropdown: true,
    dropdownItems: [
      // { name: "Events", to: "/programs/events" },
      // { name: "Trainings", to: "/programs/trainings" },
      {
        name: "Empowerment and Capacity Building",
        to: "/programs/empowerment-and-capacity-building",
      },
      { name: "Climate Action", to: "/programs/climate-action" },
      {
        name: "Global and Regional Partnership",
        to: "/programs/global-and-regional-partnership",
      },
      // {
      //   name: "Integrated Community Development",
      //   to: "/programs/integrated-community-developmemnt",
      // },
      // { name: "Nertworking Advocacy", to: "/programs/networking-advocacy" },
    ],
  },
  {
    name: "Resources",
    // to: "/resources",
    hasDropdown: true,
    dropdownItems: [
      { name: "Publications", to: "/resources/publications" },
      { name: "Forms & Formats", to: "/resources/forms-and-formats" },
      { name: "Links", to: "/resources/links" },
      { name: "Blogs", to: "/resources/blogs" },
    ],
  },
  {
    name: "Gallery",
    // to: "/gallery",
    hasDropdown: true,
    dropdownItems: [
      { name: "Training/Workshop", to: "/gallery/photos" },
      { name: "Programs", to: "/gallery/videos" },
      { name: "Networks", to: "/gallery/videos" },
    ],
  },
  {
    name: "Announcement",
    // to: "/announcement",
    hasDropdown: true,
    dropdownItems: [
      { name: "Career", to: "/announcements/careers" },
      { name: "Notice", to: "/announcements/notices" },
    ],
  },
  { name: "Contact Us", to: "/contact" },
];
