import React from "react";
import { BsDownload } from "react-icons/bs";
import { pdfjs } from "react-pdf";

// Configure pdf.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

export const PdfPreviewCard = ({ name, link, onDownload, file }) => {
  return (
    <div className="w-full bg-white overflow-hidden">
      {/* Blurred preview area */}

      {/* Content area */}
      <div className="p-4 flex space-x-3 items-center">
        {/* PDF icon */}
        <div className="flex-shrink-0">
          <div className="w-10 h-10 bg-red-500 rounded flex items-center justify-center">
            <span className="text-white font-bold text-xs">PDF</span>
          </div>
        </div>

        {/* File details */}
        <div className="flex-grow min-w-0">
          <h3 className="text-sm font-medium text-gray-900">{name}</h3>
        </div>

        {/* Download button */}
        <a href={link} target="__blank">
          <button
            // onClick={() => onDownload(file)}
            className="flex-shrink-0 text-gray-400 hover:text-gray-600 transition-colors"
            aria-label="Download file"
          >
            <BsDownload className="w-5 h-5" />
          </button>
        </a>
      </div>
    </div>
  );
};

export const PdfPreviewCardVertical = ({
  name,
  link,
  onDownload,
  file,
  photo,
}) => {
  return (
    // <div className="w-full bg-white overflow-hidden">
    //   {/* Blurred preview area */}

    //   {/* Content area */}
    //   <div className="p-4 flex space-x-3 items-center">
    //     {/* PDF icon */}
    //     <div className="flex-shrink-0">
    //       <div className="w-10 h-10 bg-red-500 rounded flex items-center justify-center">
    //         <span className="text-white font-bold text-xs">PDF</span>
    //       </div>
    //     </div>

    //     {/* File details */}
    //     <div className="flex-grow min-w-0">
    //       <h3 className="text-sm font-medium text-gray-900">{name}</h3>
    //     </div>

    //     {/* Download button */}
    //     <a href={link} target="__blank">
    //       <button
    //         // onClick={() => onDownload(file)}
    //         className="flex-shrink-0 text-gray-400 hover:text-gray-600 transition-colors"
    //         aria-label="Download file"
    //       >
    //         <BsDownload className="w-5 h-5" />
    //       </button>
    //     </a>
    //   </div>
    // </div>

    <a
      href={link}
      target="__blank"
      className="w-full mb-5 block bg-white overflow-hidden relative break-inside-avoid-column"
    >
      <img
        src={photo}
        alt={name}
        className="w-full aspect-auto object-contain"
      />
      <button
        className="flex-shrink-0 absolute right-5 top-5 bg-primary shadow-lg p-2 backdrop-blur-3xl"
        aria-label="Download file"
      >
        <BsDownload className="w-5 h-5" color="white" />
      </button>
      <p className="m-4">{name}</p>
    </a>
  );
};
