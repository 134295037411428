import React from "react";
import { Link } from "react-router-dom";
import ErrorImage from "../images/gfx/error-404.svg";

const Error404Modern = () => {
  return (
    <div>
      <div className="nk-error-ld text-center">
        <img className="nk-error-gfx" src={ErrorImage} alt="error" />
        <div className="wide-xs mx-auto">
          <h3 className="nk-error-title">Oops! Why you’re here?</h3>
          <p className="nk-error-text">
            We are very sorry for inconvenience. It looks like you’re try to
            access a page that either has been deleted or never existed.
          </p>
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <button className="mt-2 bg-primary px-4 py-1 rounded-lg">
              Back To Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Error404Modern;
