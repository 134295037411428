import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";
import { NetworkCard } from "../../components/NetworkCard";
import NormalHeader from "../../components/NormalHeader";
import { useCommonContext } from "../../hooks/useCommonContext";

const NetworkSection = () => {
  const { categories } = useCommonContext();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [networks, setNetworks] = useState(null);

  const networkCategoryId = categories?.data?.find(
    (c) => c?.slug === "networks-1"
  )?.id;

  useEffect(() => {
    const fetchNetworks = async () => {
      if (!networkCategoryId) return;

      try {
        setLoading(true);

        // Step 2: Fetch clients for each categoryId
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/clients?category_id=${networkCategoryId}`
        );

        setNetworks(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNetworks();
  }, [categories, networkCategoryId]);

  // const networks = [
  //   {
  //     name: "Maxim Alexhander",
  //     location: "CEO, Kimono Agency",
  //     image:
  //       "https://panap.net/wp-content/uploads/2020/05/Pan-Logo-New-1-2048x1076.png",
  //   },
  //   {
  //     name: "Nelson Jameson",
  //     location: "Photographer",
  //     image:
  //       "https://www.keepingchildrensafe.global/wp-content/uploads/2023/11/kcs-header-logo-1.png",
  //   },
  //   {
  //     name: "Ellie Duncan",
  //     location: "Photographer",
  //     image: "https://www.dpnet.org.np/frontend/images/logo.png",
  //   },
  //   {
  //     name: "Harold Earls",
  //     location: "Photographer",
  //     image:
  //       "https://www.ngofederation.org/uploads/logo/logo-(1)2021-08-16-11-21-21.png",
  //   },
  //   {
  //     name: "Maxim Alexhander",
  //     location: "CEO, Kimono Agency",
  //     image:
  //       "https://agroecology-coalition.org/wp-content/uploads/2023/08/AC-logo.png",
  //   },
  // ];

  return (
    <div className="bg-white relative overflow-hidden flex flex-col justify-center py-20">
      <div className="lg-container">
        {loading && <Loading />}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <>
            <NormalHeader
              animate={false}
              subTitle="Our Networks"
              title="We have been building a good networks"
            />
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {networks?.data?.[0]?.data?.map((network, index) => (
                <NetworkCard key={index} {...network} showSocial={true} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NetworkSection;
