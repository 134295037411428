import { useContext } from "react";
import { CommonContext } from "../provider/CommonProvider";
//

// ----------------------------------------------------------------------

export const useCommonContext = () => {
  const context = useContext(CommonContext);

  if (!context)
    throw new Error("useCommonContext context must be use inside AuthProvider");

  return context;
};
