import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";
import NormalHeader from "../../components/NormalHeader";
import { ProfileCard } from "../../components/ProfileCard";
import { useCommonContext } from "../../hooks/useCommonContext";

const AlumniSection = () => {
  const { categories } = useCommonContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alumnis, setAlumnis] = useState(null);

  const alumniCategoryId = categories?.data?.find(
    (c) => c?.slug === "alumni"
  )?.id;

  useEffect(() => {
    const fetchNetworks = async () => {
      if (!alumniCategoryId) return;

      try {
        setLoading(true);

        // Step 2: Fetch clients for each categoryId
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/clients?category_id=${alumniCategoryId}`
        );

        setAlumnis(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNetworks();
  }, [alumniCategoryId, categories]);

  return (
    <div className="bg-white relative overflow-hidden flex flex-col justify-center py-20">
      <div className="lg-container">
        {loading && <Loading />}
        {error && <p className="text-red-500">{error}</p>}
        {!loading &&
          !error &&
          (alumnis?.data?.[0]?.data?.length ? (
            <>
              <NormalHeader
                animate={false}
                subTitle="Our Alumni"
                title="We have a lot of alumni in our team"
              />
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {alumnis?.data?.[0]?.data?.map((alumni, index) => (
                  <ProfileCard key={index} {...alumni} />
                ))}
              </div>
            </>
          ) : (
            <div className="col-span-full text-center py-12">
              <h3 className="text-2xl font-semibold text-gray-600">
                No Alumni Currently
              </h3>
              <p className="text-gray-500 mt-4">
                Check back later for alumnis.
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AlumniSection;
