import React from "react";
import PageHeader from "../../components/PageHeader";

const SeminarWorkshopTraining = () => {
  return (
    <>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-aboutbanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Seminar Workshop Training"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Seminar Workshop Training",
              to: "/services/seminar-workshop-training",
            },
          ]}
        />
      </div>

      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container flex flex-col gap-5">
          <section class="text-lg leading-relaxed">
            <h1 class="text-3xl font-bold text-gray-900 mb-2">
              Training Facilities
            </h1>
            <div className="space-y-6">
              <p>
                On request, special training courses on development and
                humanitarian topics are organized/conducted for groups of at
                least 15 participants at SWI training center. A team of
                experienced, experienced and professional Trainers and Subject
                Specialists conduct the training sessions. On certain occasions,
                resource persons from outside organizations are engaged for part
                or all of a selected training program. Field visit and practical
                demonstrations can be arranged in nearby Kathmandu valley or SWI
                project areas or as client’s requirement.
              </p>
              <p>
                SWI training center offers the following training program for
                the year 2019. The content, duration, methodology and cost can
                be customized according to the request of the clients.
              </p>
            </div>
          </section>

          <section>
            <h2 class="text-3xl font-bold text-gray-900 mb-2">Our Trainings</h2>

            <ul class="space-y-4 text-lg">
              <li class="flex items-start">
                <span class="text-green-500 mr-2">✔</span>
                There are many variations of passages of Lorem Ipsum.
              </li>
              <li class="flex items-start">
                <span class="text-green-500 mr-2">✔</span>
                Available but the majority have alteration in some injected
                words.
              </li>
              <li class="flex items-start">
                <span class="text-green-500 mr-2">✔</span>
                There are many variations of passages of Lorem Ipsum which don’t
                look even slightly believable.
              </li>
            </ul>

            <ol class="list-decimal list-inside mt-6 space-y-4 text-lg">
              <li>
                <span class="font-bold">
                  Humanitarian Response and Disaster Risk Reduction
                </span>
                <ul class="list-disc list-inside ml-6 space-y-2">
                  <li>
                    Disaster risk reduction and management for community
                    resilient
                  </li>
                  <li>
                    Sphere standard/initial rapid assessment/multi-sector need
                    assessment
                  </li>
                  <li>Cash Transfer programming and market assessment</li>
                </ul>
              </li>
              <li>
                <span class="font-bold">Project Cycle Management</span>
                <ul class="list-disc list-inside ml-6 space-y-2">
                  <li>Project cycle management</li>
                  <li>
                    Fund raising strategy development and proposal writing
                  </li>
                  <li>
                    Financial and HR management for non-profit organization
                  </li>
                  <li>Supply chain management</li>
                </ul>
              </li>
              <li>
                <span class="font-bold">
                  Monitoring, evaluation, Accountability and Learning
                </span>
                <ul class="list-disc list-inside ml-6 space-y-2">
                  <li>
                    Result based Monitoring, Evaluation, Accountability and
                    Learning (MEAL)
                  </li>
                  <li>
                    Data management tools and techniques (SPSS, Kobo, MAGPI,
                    GIS, Drone, etc.)
                  </li>
                  <li>
                    Action Research/Participatory research tools and techniques
                  </li>
                  <li>Governance/Core humanitarian standards/Accountability</li>
                  <li>Communication, documentation and report writing</li>
                </ul>
              </li>
              <li>
                <span class="font-bold">
                  Advocacy, networking and protection
                </span>
                <ul class="list-disc list-inside ml-6 space-y-2">
                  <li>Cooperative management</li>
                  <li>Child safe gardening/Child protection</li>
                  <li>Advocacy and networking</li>
                  <li>Gender Equity and Social Inclusion (GESI)</li>
                  <li>Conflict management and peace building</li>
                </ul>
              </li>
              <li>
                <span class="font-bold">
                  Livelihood, food security and food sovereignty
                </span>
                <ul class="list-disc list-inside ml-6 space-y-2">
                  <li>
                    Basic training on livelihood, food security and food
                    sovereignty
                  </li>
                  <li>Permaculture/Ecological farming</li>
                  <li>Livestock management</li>
                </ul>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </>
  );
};

export default SeminarWorkshopTraining;
