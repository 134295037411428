import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";
import NormalHeader from "../../components/NormalHeader";
import { PartnerCard } from "../../components/PartnerCard";
import { useCommonContext } from "../../hooks/useCommonContext";

const PartnerSection = () => {
  const { categories } = useCommonContext();
  const [partnersByCategory, setPartnersByCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const partnerCategoryId = categories?.data?.find(
    (c) => c?.slug === "partner"
  )?.id;

  useEffect(() => {
    const fetchPartnersByCategories = async () => {
      if (!partnerCategoryId) return;

      try {
        setLoading(true);

        // Step 1: Fetch categories related to the partnerCategoryId
        const relatedCategories = categories?.data?.filter(
          (c) => c?.category_id === partnerCategoryId
        );

        if (!relatedCategories.length) {
          throw new Error("No related categories found.");
        }

        const categoryIds = relatedCategories.map((cat) => ({
          id: cat.id,
          name: cat.name,
        }));

        // Step 2: Fetch clients for each categoryId
        const partnerRequests = categoryIds.map((cat) =>
          axios.get(
            `${process.env.REACT_APP_BASE_URL}/clients?category_id=${cat.id}`
          )
        );

        const responses = await Promise.all(partnerRequests);

        // Step 3: Create an object to store partners by category
        const partnersByCategoryData = {};
        responses.forEach((response, index) => {
          const categoryName = categoryIds[index].name;
          partnersByCategoryData[categoryName] = response.data;
        });

        setPartnersByCategory(partnersByCategoryData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnersByCategories();
  }, [categories, partnerCategoryId]);

  return (
    <div className="bg-white relative overflow-hidden flex flex-col justify-center py-20">
      <div className="lg-container">
        {loading && <Loading />}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <>
            <NormalHeader
              animate={false}
              subTitle="Our Partners"
              title="Funding partners"
            />
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {partnersByCategory?.["Funding partners"]?.data?.[0]?.data?.map(
                (data, index) => (
                  <PartnerCard key={index} showSocial={true} {...data} />
                )
              )}
            </div>

            <div className="py-10">
              <NormalHeader
                animate={false}
                subTitle="Our Partners"
                title="Implementing partners"
              />
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {partnersByCategory?.[
                  "Implementing partners"
                ]?.data?.[0]?.data?.map((data, index) => (
                  <PartnerCard key={index} showSocial={true} {...data} />
                ))}{" "}
              </div>
            </div>

            <div className="py-10">
              <NormalHeader
                animate={false}
                subTitle="Our Partners"
                title="Our Technical partner"
              />
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {partnersByCategory?.[
                  "Technical partners"
                ]?.data?.[0]?.data?.map((data, index) => (
                  <PartnerCard key={index} showSocial={true} {...data} />
                ))}{" "}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PartnerSection;
