import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../common/Loading";
import PageHeader from "../../../components/PageHeader";
import BlogDescription from "../../../sections/blog/BlogDescription";

const BlogDetail = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { slug } = useParams();

  useEffect(() => {
    const fetchSingleBlog = async () => {
      try {
        setLoading(true);

        // Step 2: Fetch clients for each categoryId
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/blogs/${slug}`
        );

        setBlog(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSingleBlog();
  }, [slug]);

  return (
    <>
      {loading && (
        <div className="h-screen">
          <Loading />
        </div>
      )}
      {error && <p className="text-red-500">{error}</p>}

      {!loading && !error && (
        <div>
          <div
            // ref={bannerRef}
            className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
          >
            <div
              // ref={backgroundRef}
              className="absolute inset-0 bg-aboutbanner bg-top bg-cover bg-no-repeat"
            />
            {/* Black overlay with gradient */}
            <div
              className="absolute inset-0"
              style={{
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5) 75%)",
              }}
            />
            <PageHeader
              titleSize={10}
              title={blog?.name}
              breadcrumbs={[
                {
                  name: "Home",
                  to: "/",
                },

                {
                  name: "Blogs",
                  to: "/resources/blogs",
                },

                {
                  name: "Give health support for every homeless poor children",
                  to: "/blog/category/slug",
                },
              ]}
            />
          </div>

          <BlogDescription data={blog} />
        </div>
      )}
    </>
  );
};

export default BlogDetail;
