/* eslint-disable jsx-a11y/heading-has-content */
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/all";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

gsap.registerPlugin(TextPlugin);

const PageHeader = ({ title, breadcrumbs, subTitle, paragraph, titleSize }) => {
  const container = useRef(null); // Reference to the entire container
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      gsap.to(titleRef.current, {
        text: { value: title },
        duration: 5,
        ease: "linear",
        repeat: 0,
        stagger: 0.1,
      });
    }
  }, [title]);

  useGSAP(
    () => {
      // Title animation: Typewriter effect
      gsap.to(".title", {
        text: { value: title },
        duration: 5,
        ease: "linear",
        repeat: 0,
        stagger: 0.1,
      });

      // Subtitle fade-in animation
      if (subTitle) {
        gsap.from(".subtitle", {
          opacity: 0,
          delay: 0.5,
          duration: 1,
          stagger: 0.1,
          y: 20,
          scrollTrigger: {
            trigger: container.current,
            start: "top 80%",
            end: "bottom top",
            // scrub: true,
          },
        });
      }

      // Paragraph scale-in animation
      if (paragraph) {
        gsap.from(".paragraph", {
          opacity: 0,
          delay: 0.5,
          duration: 1,
          y: 20,
          stagger: 0.1,
          scrollTrigger: {
            trigger: container.current,
            start: "top 80%",
            end: "bottom top",
            // scrub: true,
          },
        });
      }
    },
    { scope: container }
  );

  return (
    <div
      ref={container} // Attach the container reference here
      className="absolute bottom-[50%] left-[50%] translate-x-[-50%] translate-y-[50%] z-[1]"
    >
      <div className="flex flex-col items-center justify-center text-white">
        {/* SubTitle with fade-in effect */}
        {subTitle ? (
          <div className="flex items-center mb-4 gap-3">
            <div className="h-[4px] bg-primary flex-1 w-20" />
            <h2 className="text-xl font-bold subtitle text-white cursive">
              {subTitle}
            </h2>
            <div className="h-[4px] bg-primary flex-1 w-20" />
          </div>
        ) : null}

        {/* Title with typewriter effect */}
        <h1
          className="text-5xl font-bold mb-4 uppercase title font-title text-center"
          ref={titleRef}
        />

        {/* Paragraph with scale-in effect */}
        {paragraph ? (
          <h2 className="text-xl mb-4 paragraph text-white text-center">
            {paragraph}
          </h2>
        ) : null}

        {/* Breadcrumbs */}
        <div className="flex items-center space-x-2">
          {breadcrumbs?.map((item, index) => (
            <div key={item.name} className="flex items-center">
              <Link
                to={item.to}
                className={
                  index === breadcrumbs.length - 1
                    ? "text-white"
                    : "text-orange-400 hover:text-orange-300"
                }
              >
                {item.name}
              </Link>
              {index < breadcrumbs.length - 1 && (
                <span className="mx-2">{">"}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
