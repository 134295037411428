import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiBell, BiCalendar } from "react-icons/bi";
import { BsInfo } from "react-icons/bs";
import { CgCalendar } from "react-icons/cg";
import { LuUserSquare } from "react-icons/lu";
import Loading from "../../../common/Loading";
import PageHeader from "../../../components/PageHeader";
import { useCommonContext } from "../../../hooks/useCommonContext";

export default function NoticesPage() {
  const { categories } = useCommonContext();
  const [notices, setNotices] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/blogs`,
          {
            params: {
              category_id: categories?.data?.find(
                (cat) => cat?.slug === "notice"
              )?.id,
            },
          }
        );
        setNotices(response.data.data[0].data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNotices();
  }, [categories?.data]);

  const getCategoryIcon = (category) => {
    switch (category?.toLowerCase()) {
      case "event":
        return <BiCalendar className="h-5 w-5 text-purple-500" />;
      case "volunteers":
        return <LuUserSquare className="h-5 w-5 text-green-500" />;
      case "policy":
        return <BsInfo className="h-5 w-5 text-blue-500" />;
      default:
        return <BiBell className="h-5 w-5 text-orange-500" />;
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="text-red-500 text-center py-10">{error}</div>;
  }

  return (
    <>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-announcementBanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Important Notices"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Notices",
              to: "/announcements/notices",
            },
          ]}
        />
      </div>
      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container">
          {notices?.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {notices?.map((notice, index) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={notice?.photo}
                  key={index}
                  className="bg-transparent rounded-3xl group shadow-md overflow-hidden relative cursor-pointer block"
                >
                  {/* Background decoration */}
                  <div className="absolute inset-0">
                    <div className="absolute top-0 left-0 w-32 h-32 bg-yellow-100 rounded-full opacity-40 transform translate-x-[-50%] translate-y-[-50%]"></div>
                    <div className="absolute bottom-0 right-0 w-40 h-40 bg-yellow-100 rounded-full opacity-40 transform translate-x-[50%] translate-y-[50%]"></div>
                  </div>

                  {/* Image Section */}
                  <img
                    src={notice?.photo || "/default-notice-image.png"}
                    alt="Notice Background"
                    className="w-full h-64 rounded-3xl object-cover grayscale group-hover:grayscale-0 duration-150"
                  />

                  {/* Content Section */}
                  <div className="p-5 relative z-10">
                    {/* Date and Tag */}
                    <div className="flex items-center text-gray-500 space-x-3 text-sm">
                      <div className="flex items-center space-x-1">
                        <CgCalendar size={15} />
                        <span>
                          {moment(notice?.created_at).format("MMM DD, YYYY")}
                        </span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <span>•</span>
                        <span className="text-green-600 font-medium">
                          {notice?.category?.name}
                        </span>
                      </div>
                    </div>

                    <div className="mt-4">
                      <h3 className="font-semibold text-lg mb-2 text-start line-clamp-2">
                        {notice?.name}
                      </h3>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          ) : (
            <div className="col-span-full text-center py-12">
              <h3 className="text-2xl font-semibold text-gray-600">
                No Notices Currently
              </h3>
              <p className="text-gray-500 mt-4">
                Check back later for new notices.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
