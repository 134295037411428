import React from "react";
import PageHeader from "../../components/PageHeader";

const ClimateAction = () => {
  return (
    <>
      <div className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center">
        <div className="absolute inset-0 bg-climateBg bg-top bg-cover bg-no-repeat" />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Climate Action"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },
            {
              name: "Climate Actiong",
              to: "/services/climate-action",
            },
          ]}
        />
      </div>

      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container flex flex-col gap-5">
          <section className="text-lg leading-relaxed">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">
              Climate Action
            </h1>
            <ul className="space-y-6 list-decimal list-inside">
              <li className="font-bold">Agroecology</li>
              <div>
                <p>
                  As we believe in nurturing a harmonious relationship between
                  agriculture, human and the environment. Our Agroecology
                  Project is evidence to this vision, promoting sustainable
                  farming practices that benefit the peasant communities,
                  empowering women and other marginalized communities.
                </p>
                <p>
                  In the pursuit of infinite growth, humans have disturbed the
                  natural dynamic balance of the mother earth. We are rapidly
                  failing the ecosystems of the soil, agriculture, forest,
                  aquatic and agriculture. These systems, maintained a dynamic
                  balance in themselves, and with the whole; naturally. Synergy,
                  co-evolution, cooperation and partnership, and networks
                  enmeshed seamlessly, with the capacity to generate and
                  regenerate without human intervention.
                </p>

                <p>
                  Our project focuses on recognizing the traditional
                  agricultural practices, promotion of local seeds, adaptation
                  of eco-friendly agriculture technologies, climate resilient
                  and chemical free input.
                </p>
                <ul className="space-y-3">
                  <li className="font-bold ml-4">
                    A) Promotion of organic farming
                  </li>

                  <p>
                    We promote and support pesticide-free and chemical-free
                    farming practices, empowering farmers to protect soil
                    health, preserve biodiversity, and cultivate
                    climate-resilient agricultural systems that ensure the food
                    security in long run.
                  </p>
                </ul>

                <ul className="space-y-3">
                  <li className="font-bold ml-4">
                    B) Encouragement for Agroecological practices
                  </li>

                  <p>
                    We provide comprehensive training and aware farmers to shift
                    from conventional to organic farming practices. We also
                    facilitate the knowledge exchange among the farmers. Now we
                    are trying to be creating the evidence and encouraging
                    farmers to adopt and use the agroecology practices. By
                    applying agroecological methods, such as recycling organic
                    materials and minimizing the use of external inputs, using
                    traditional seeds we support farmers create sustainable
                    farming systems that enhance productivity and resilience.
                    These methods promote efficient resource use, reduce
                    dependency on external inputs, and strengthen the capacity
                    of farmers to adapt to environmental degradation. This
                    approach integrates scientific knowledge with traditional
                    wisdom, in addition to a holistic transformation that
                    benefits both the environment and the livelihoods of farming
                    communities.
                  </p>
                </ul>

                <ul className="space-y-3">
                  <li className="font-bold ml-4">C) Involvement of Women</li>

                  <p>
                    Recognizing the pivotal role women play in promoting
                    sustainable agricultural practices, we actively engage them
                    in organic farming initiatives. By providing support and
                    opportunities for income-generating activities, we empower
                    women to achieve economic independence encouraging them to
                    be involved in cooperatives, saving and credit mobilization
                    while nurturing their leadership within communities. This
                    inclusive approach not only strengthens their role in
                    advancing sustainable farming but also contributes to
                    building resilient, their interpersonal development, and
                    self-reliant communities that helps environmental
                    equilibrium.
                  </p>
                </ul>

                <ul className="space-y-3">
                  <li className="font-bold ml-4">
                    D) Local and indigenous seed preservation
                  </li>

                  <p>
                    We are committed to preserve the local and indigenous seeds
                    that are at risk of disappearing, we actively promoting
                    their cultivation and encourage farmers to incorporate them
                    into their farming practices. To expand the awareness among
                    other farmers, we organize annual local seed exchange
                    programs across our project areas. These events bring
                    farmers together to share seeds and exchange traditional
                    farming knowledge, that enhances a sense of communal
                    collaboration. By reviving and preserving these seeds, we
                    not only protect agricultural system but also enhance the
                    resilience and sustainability of farming systems for future
                    generations.
                  </p>
                </ul>

                <ul className="space-y-3">
                  <li className="font-bold ml-4">E) Engagement of youth</li>

                  <p>
                    In the realm of globalization, the interest and involvement
                    of youth in agriculture are steadily declining. To address
                    this challenge, we inspire and empower young people to
                    become advocates and leaders in agroecology. By equipping
                    them with the knowledge, skills, and tools to adopt
                    sustainable practices, we aim to rejuvenate their connection
                    to agriculture and encourage a new generation of
                    environmentally conscious farmers. This initiative not only
                    promotes eco-friendly farming methods but also ensures the
                    continuity of sustainable agriculture, securing food
                    security and environmental health for future generations.
                  </p>
                </ul>

                <h1 className="text-2xl font-bold text-gray-900  mt-8">
                  To promote Agroecology, we are providing:
                </h1>
                <div className="mt-4">
                  <h3 className="font-bold">Training and capacity building</h3>
                  <p>
                    We are committed to empowering farmers through comprehensive
                    training and capacity-building initiatives. Some trainings
                    are consisted of organic farming, permaculture, and the
                    theories and principles of agroecology. We organize
                    interactive workshops, knowledge-sharing sessions, and
                    exchange and learning visits to promote collaboration and
                    practical learnings. Additionally, we facilitate hands-on
                    experimentation in trial farms, enabling farmers to apply
                    and refine agroecological techniques.
                  </p>

                  <p>
                    We identify model farmers across the area and connect the
                    farmers to each other to exchange the knowledge.
                  </p>
                </div>

                <div className="mt-4">
                  <h3 className="font-bold">Model Farms</h3>
                  <p>
                    To encourage the experimentation of different verities of
                    local and indigenous seed varieties, and their preservation,
                    we support young farmers in establishing trial farms. These
                    farms serve as testing grounds where farmers can evaluate
                    the usability and resiliency of local seed varieties under
                    diverse conditions. In addition, the trial farms function as
                    learning sites, providing hands-on educational opportunities
                    for youth networks and other farmers. This initiative
                    furthers innovation, preserve agricultural biodiversity, and
                    inspire the next generation to adopt sustainable and
                    resilient farming practices while preserving the local
                    varieties.
                  </p>
                </div>

                <div className="mt-4">
                  <h3 className="font-bold">Collaborative approach</h3>
                  <p>
                    We actively engage local communities and collaborate with
                    local governments to ensure needs and sustainability of our
                    initiatives. Moreover, we also collaborate with cooperatives
                    to strengthen the partnership approach and mobilizing women
                    and youths by ensuring their independency by engaging them
                    in organic farming practices. This collaborative approach
                    not only ensures the relevance and effectiveness of our
                    initiatives but also enables communities to empower
                    themselves.
                  </p>
                </div>

                <div className="mt-4">
                  <h3 className="font-bold">Shorten the value chain</h3>
                  <p>
                    By connecting farmers with local markets, we help them
                    achieve fair prices for their organic products by avoiding
                    middlemen and long value chain system.
                  </p>
                </div>
              </div>

              <li className="font-bold">
                Preservation of traditional knowledge and practices
              </li>
              <div>
                <p>
                  With the realm of climate crisis, we are also working on
                  preservation of traditional practices, which are eco-friendly
                  and climate resilient. As we are working in Tharu community,
                  their traditional practices of making and using Deheri, seed
                  preservation techniques, traditional knowledges of farming,
                  and preservation of uncultivated and semi-cultivated food are
                  some instances.
                </p>
              </div>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default ClimateAction;
