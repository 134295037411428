import React from "react";
import { useParams } from "react-router-dom";
import Error404Modern from "../error/404-modern";
import Blog from "./blog/Blog";
import FormsFormats from "./FormsFormats";
import Links from "./Links";
import Publications from "./Publications";

const ResourcesIndex = () => {
  const { category } = useParams();

  const renderItem = (item) => {
    switch (item) {
      case "blogs":
        return <Blog />;

      case "publications":
        return <Publications />;

      case "forms-and-formats":
        return <FormsFormats />;

      case "links":
        return <Links />;

      default:
        return <Error404Modern />;
    }
  };

  return renderItem(category);
};

export default ResourcesIndex;
