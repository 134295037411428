import React from "react";
import { FaRegMap } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMailOutline } from "react-icons/md";
import { ContactCard } from "../../components/ContactCard";
import NormalHeader from "../../components/NormalHeader";

const Branches = () => {
  return (
    <div className="py-20">
      <NormalHeader
        animate={true}
        subTitle="Our Branches"
        title="We serve from following branches"
      />
      <div className="lg-container flex gap-20 rounded-2xl flex-col md:flex-row">
        <div className="flex items-center gap-5 flex-col md:flex-row">
          {/* Contact Details */}
          <div className="bg-card flex flex-col gap-5 flex-1 w-full">
            <ContactCard
              icon={<FaRegMap size={40} color="white" />}
              title="Address"
              details="Surkhet, Nepal"
            />
            <ContactCard
              icon={<LuPhoneCall size={40} color="white" />}
              title="Phone"
              details="+977-1-5185315"
            />
            <ContactCard
              icon={<MdOutlineMailOutline size={40} color="white" />}
              title="Email"
              details="info@swi-nepal.org"
            />
          </div>

          {/* Map */}
          <div className="bg-card flex-[2] w-full">
            <iframe
              title="Map"
              className="rounded-md h-[20rem]"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.519817975008!2d85.30120427531622!3d27.66387412740784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19d2deb30cdf%3A0x242b0168281aeaf6!2sSocial%20Work%20Institute!5e1!3m2!1sen!2snp!4v1732636215596!5m2!1sen!2snp"
              allowFullScreen=""
              loading="lazy"
              width={"100%"}
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div className="flex items-center gap-5 flex-col md:flex-row">
          {/* Contact Details */}
          <div className="bg-card flex flex-col gap-5 flex-1 w-full">
            <ContactCard
              icon={<FaRegMap size={40} color="white" />}
              title="Address"
              details="Hasanpur, Dhangadhi"
            />
            <ContactCard
              icon={<LuPhoneCall size={40} color="white" />}
              title="Phone"
              details="091-526432"
            />
            <ContactCard
              icon={<MdOutlineMailOutline size={40} color="white" />}
              title="Email"
              details="info@swi-nepal.org"
            />
          </div>

          {/* Map */}
          <div className="bg-card flex-[2] w-full">
            <iframe
              title="Map"
              className="rounded-md h-[20rem]"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.519817975008!2d85.30120427531622!3d27.66387412740784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19d2deb30cdf%3A0x242b0168281aeaf6!2sSocial%20Work%20Institute!5e1!3m2!1sen!2snp!4v1732636215596!5m2!1sen!2snp"
              allowFullScreen=""
              loading="lazy"
              width={"100%"}
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branches;
