/* eslint-disable jsx-a11y/heading-has-content */
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/all";
import { useRef } from "react";
import { Link } from "react-router-dom";

gsap.registerPlugin(TextPlugin);

const NormalHeader = ({
  title,
  breadcrumbs,
  subTitle,
  paragraph,
  animate = true,
  mb = true,
}) => {
  const container = useRef(null); // Reference to the entire container
  const titleRef = useRef(null);

  useGSAP(
    () => {
      // Title animation: Typewriter effect when container is in the viewport
      if (animate) {
        gsap.from(".title", {
          scrollTrigger: {
            trigger: container.current,
            start: "top 100%",
            end: "bottom top",
            scrub: true,
          },
          opacity: 0,
          delay: 0.5,
          duration: 1,
          stagger: 0.5,
          y: -20,
        });
      }

      // Subtitle fade-in animation when container is in the viewport
      if (subTitle && animate) {
        gsap.from(".subtitle", {
          scrollTrigger: {
            trigger: container.current,
            start: "top 100%",
            end: "bottom top",
            scrub: true,
          },
          opacity: 0,
          delay: 0.5,
          duration: 1,
          stagger: 0.1,
          y: 20,
        });
      }

      // Paragraph scale-in animation when container is in the viewport
      if (paragraph && animate) {
        gsap.from(".paragraph", {
          scrollTrigger: {
            trigger: container.current,
            start: "top 80%",
            end: "bottom top",
            scrub: true,
          },
          opacity: 0,
          delay: 0.5,
          duration: 1,
          stagger: 0.1,
          y: 20,
        });
      }
    },
    { scope: container }
  );

  return (
    <div
      ref={container} // Attach the container reference here
      className={`${mb ? "mb-16" : "mb-0"}`}
    >
      <div className="flex flex-col items-center justify-center text-black">
        {/* SubTitle with fade-in effect */}
        {subTitle ? (
          <div className="flex items-center mb-4 gap-3 subtitle">
            <div className="h-[4px] bg-primary flex-1 w-20" />
            <h2 className="text-2xl font-bold  text-black cursive">
              {subTitle}
            </h2>
            <div className="h-[4px] bg-primary flex-1 w-20" />
          </div>
        ) : null}

        {/* Title with typewriter effect */}
        <h1
          className="text-5xl font-bold mb-4 uppercase title font-title max-w-[50rem] text-center"
          ref={titleRef}
        >
          {title}
        </h1>

        {/* Paragraph with scale-in effect */}
        {paragraph ? (
          <h2 className="text-xl font-bold mb-4 paragraph text-gray-300">
            {paragraph}
          </h2>
        ) : null}

        {/* Breadcrumbs */}
        <div className="flex items-center space-x-2">
          {breadcrumbs?.map((item, index) => (
            <div key={item.name} className="flex items-center">
              <Link
                to={item.to}
                className={
                  index === breadcrumbs.length - 1
                    ? "text-white"
                    : "text-orange-400 hover:text-orange-300"
                }
              >
                {item.name}
              </Link>
              {index < breadcrumbs.length - 1 && (
                <span className="mx-2">{">"}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NormalHeader;
