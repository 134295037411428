import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import PageHeader from "../../../components/PageHeader";
import BlogSection from "../../../sections/blog/BlogSection";

gsap.registerPlugin(ScrollTrigger);

const Blog = () => {
  const bannerRef = useRef(null);
  const backgroundRef = useRef(null);

  useEffect(() => {
    // Parallax effect for the banner background image
    if (backgroundRef.current && bannerRef.current) {
      gsap.to(backgroundRef.current, {
        yPercent: -10, // Faster upward scroll for the background
        ease: "none",
        scrollTrigger: {
          trigger: bannerRef.current,
          start: "top top",
          end: "bottom top",
          scrub: true, // Smooth scrolling sync
        },
      });
    }
  }, []);

  return (
    <div>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-aboutbanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Blogs"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Blogs",
              to: "/resources/blogs",
            },
          ]}
        />
      </div>

      <BlogSection />
    </div>
  );
};

export default Blog;
