import React from "react";

const CountSection = () => {
  return (
    <div className="bg-primary text-white flex items-center py-20 lg:py-0 mb-20">
      <div className="flex gap-10 text-start lg-container lg:w-full">
        {/* Left Section: Text Content */}
        <div className="flex flex-col gap-6 flex-1 lg:pl-[5%] pl-0">
          <div className="flex flex-col gap-6 lg:py-20 py-0">
            <h1 className="text-5xl font-bold leading-tight font-title">
              We Empower the Unempowered
            </h1>
            <p className="">
              We are committed to empower the unempowered segments of the
              societies and act against climate crisis. We empower the rural
              youths by providing trainings, women by engaging them in saving
              and credit mobilization, organizing different awareness campaigns
              and workshops to build their capacities. We also act against
              climate crisis through the promotion and practices of Agroecology,
              preservation of traditional knowledge and practices, mobilization
              of young changemakers, and peasant communities along with building
              their capacity and uplifting their living standard.
            </p>

            {/* Statistics Section */}
            {/* <div className="grid grid-cols-2 lg:grid-cols-2 gap-6">
              <div>
                <p className="text-6xl font-bold text-secondary">15k+</p>
                <p className="mt-1 text-xl">Total campaign</p>
              </div>
              <div>
                <p className="text-6xl font-bold text-secondary">1k+</p>
                <p className="mt-1 text-xl">Satisfied partners</p>
              </div>
              <div>
                <p className="text-6xl font-bold text-secondary">400+</p>
                <p className="mt-1 text-xl">Events Organized</p>
              </div>
              <div>
                <p className="text-6xl font-bold text-secondary">35k+</p>
                <p className="mt-1 text-xl">Happy volunteers</p>
              </div>
            </div> */}
          </div>
        </div>

        {/* Right Section: Image */}
        <div className="flex-1 hidden lg:block">
          <img
            src="/home/help.jpeg"
            alt="Needy People"
            className="w-full object-cover h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default CountSection;
