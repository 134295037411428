export const ContactCard = ({ icon, title, details }) => {
  return (
    <div className="flex items-center gap-5">
      <div className="flex items-center justify-center w-24 h-24 rounded-xl bg-primary">
        {icon}
      </div>
      <div className="flex flex-col gap-2 flex-1">
        <h3 className="text-4xl">{title}</h3>
        <p className="text-muted-foreground">{details}</p>
      </div>
    </div>
  );
};
