import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { menuItems } from "../routes/menu";

const MobileNav = ({ isOpen, onClose }) => {
  const navRef = useRef(null);
  const containerRef = useRef(null);
  const [openAccordions, setOpenAccordions] = useState({});

  useEffect(() => {
    if (navRef.current) {
      if (isOpen) {
        gsap.to(navRef.current, {
          x: "0%",
          duration: 0.3,
          ease: "power2.out",
        });
      } else {
        gsap.to(navRef.current, {
          x: "100%",
          duration: 0.3,
          ease: "power2.in",
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        isOpen
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1145 && isOpen) {
        onClose();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen, onClose]);

  const toggleAccordion = (name) => {
    setOpenAccordions((prev) => {
      const newState = { ...prev, [name]: !prev[name] };
      const accordionContent = document.getElementById(`accordion-${name}`);

      if (accordionContent) {
        if (newState[name]) {
          gsap.to(accordionContent, {
            height: "auto",
            duration: 0.3,
            ease: "power2.out",
            onStart: () => (accordionContent.style.display = "block"),
          });
        } else {
          gsap.to(accordionContent, {
            height: 0,
            duration: 0.3,
            ease: "power2.in",
            onComplete: () => (accordionContent.style.display = "none"),
          });
        }
      }

      return newState;
    });
  };

  return (
    <div
      ref={(el) => {
        navRef.current = el;
        containerRef.current = el;
      }}
      className="fixed top-0 right-0 min-w-[50%] h-full bg-white shadow-lg transform translate-x-full z-50 overflow-y-auto"
    >
      <div className="p-4">
        <button
          onClick={onClose}
          className="absolute top-4 right-4"
          aria-label="Close mobile navigation"
        >
          <FaTimes className="w-6 h-6" />
        </button>
        <nav className="mt-[4rem]">
          <ul className="space-y-4">
            {menuItems.map((item) => (
              <li key={item.name} className="border-b border-gray-200 pb-2">
                {item.hasDropdown ? (
                  <div>
                    <button
                      onClick={() => toggleAccordion(item.name)}
                      className="flex justify-between items-center w-full text-left text-lg font-medium hover:text-primary"
                    >
                      {item.name}
                      <FaChevronDown
                        className={`transition-transform duration-300 ${
                          openAccordions[item.name] ? "rotate-180" : ""
                        }`}
                      />
                    </button>
                    <div
                      id={`accordion-${item.name}`}
                      className="overflow-hidden"
                      style={{ height: 0, display: "none" }}
                    >
                      <ul className="pl-4 mt-2 space-y-2">
                        {item.dropdownItems.map((subItem) => (
                          <li key={subItem.name}>
                            <Link
                              to={subItem.to}
                              className="text-base hover:text-primary"
                              onClick={onClose}
                            >
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <Link
                    to={item.to}
                    className="text-lg font-medium hover:text-primary"
                    onClick={onClose}
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileNav;
