import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCalendar, BsGeoAlt } from "react-icons/bs";
import { HiOutlineBadgeCheck, HiX } from "react-icons/hi";
import * as Yup from "yup";
import Loading from "../../../common/Loading";
import PageHeader from "../../../components/PageHeader";

export default function CareerPage() {
  const [careers, setCareers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [applicationForm, setApplicationForm] = useState({
    name: "",
    email: "",
    phone: "",
    education: "",
    skills: "",
    previousWorks: "",
    address: "",
    documents: {},
  });
  const [formErrors, setFormErrors] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Full Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    education: Yup.string().required("Education details are required"),
    skills: Yup.string()
      .required("Skills are required")
      .test(
        "is-comma-separated",
        "Skills must be comma-separated",
        (value) => value.split(",").length > 0 && value.trim() !== ""
      ),
    previousWorks: Yup.string().required("Previous work details are required"),
    address: Yup.string().required("Address is required"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplicationForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDocumentUpload = (docName, e) => {
    const file = e.target.files[0];

    // Validate file size and type before setting
    if (file) {
      // Check file size (less than 1MB)
      if (file.size > 1 * 1024 * 1024) {
        alert(`${docName} file must be less than 1MB`);
        e.target.value = ""; // Clear the file input
        return;
      }

      // Check file type
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        alert(`${docName} must be a PNG, JPEG, or JPG file`);
        e.target.value = ""; // Clear the file input
        return;
      }

      setApplicationForm((prev) => ({
        ...prev,
        documents: {
          ...prev.documents,
          [docName]: file,
        },
      }));
    }
  };

  const handleSubmitApplication = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      // Validate the form
      await validationSchema.validate(applicationForm, { abortEarly: false });

      // Additional document validation
      const requiredDocs = selectedJob.required_docs;
      for (let doc of requiredDocs) {
        const file = applicationForm.documents[doc];

        if (!file) {
          alert(`Please upload ${doc}`);
          return;
        }

        // Validate file size
        if (file.size > 1 * 1024 * 1024) {
          alert(`${doc} file must be less than 1MB`);
          return;
        }

        // Validate file type
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          alert(`${doc} must be a PNG, JPEG, or JPG file`);
          return;
        }
      }

      // Create FormData for file upload
      const formData = new FormData();
      formData.append("name", applicationForm.name);
      formData.append("email", applicationForm.email);
      formData.append("phone", applicationForm.phone);
      formData.append("education", applicationForm.education);
      formData.append("skills", applicationForm.skills);
      formData.append("previous_work", applicationForm.previousWorks);
      formData.append("address", applicationForm.address);
      formData.append("career_id", selectedJob.id);

      // Append required documents
      selectedJob.required_docs.forEach((doc) => {
        if (applicationForm.documents[doc]) {
          formData.append("doc[]", applicationForm.documents[doc]);
        }
      });

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/applications`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Reset form and close modal
      setApplicationForm({
        name: "",
        email: "",
        phone: "",
        education: "",
        skills: "",
        previousWorks: "",
        address: "",
        documents: {},
      });
      setSelectedJob(null);
      setFormErrors({});

      // Show success message or handle success
      alert("Application submitted successfully!");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Handle validation errors
        const errors = {};
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
        setFormErrors(errors);
      } else {
        // Handle submission errors
        console.error("Application submission error:", error);
        if (error.response) {
          const errorMessage = error.response.data.message;
          if (errorMessage) {
            alert(errorMessage);
          } else {
            alert("Failed to submit application. Please try again.");
          }
        } else {
          alert("Failed to submit application. Please try again.");
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleDescription = (careerId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [careerId]: !prev[careerId],
    }));
  };

  useEffect(() => {
    // Reset form when a new job is selected
    if (selectedJob) {
      setApplicationForm({
        name: "",
        email: "",
        phone: "",
        education: "",
        skills: "",
        previousWorks: "",
        address: "",
        documents: {},
      });
      setFormErrors({});
    }
  }, [selectedJob]);

  useEffect(() => {
    const fetchCareers = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/careers`,
          {
            params: {
              expired: 0,
            },
          }
        );

        setCareers(response.data.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCareers();
  }, []);

  return (
    <>
      <div className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center">
        <div className="absolute inset-0 bg-announcementBanner bg-center bg-cover bg-no-repeat" />
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Join Our Mission"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },
            {
              name: "Careers",
              to: "/announcements/careers",
            },
          ]}
        />
      </div>
      <div className="bg-gray-50 relative overflow-hidden py-16">
        <div className="container mx-auto px-4">
          {loading && (
            <div className="flex justify-center">
              <Loading />
            </div>
          )}

          {error && (
            <div className="text-center text-red-500 text-lg">{error}</div>
          )}

          {!loading && !error && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {careers?.length ? (
                careers?.map((career, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 border border-gray-100"
                  >
                    <div className="p-6">
                      <div className="flex items-start space-x-4 mb-4">
                        <div>
                          <h3 className="text-xl font-semibold text-gray-900">
                            {career.name}
                          </h3>
                          <p className="text-sm text-gray-500">
                            {career.office} - {career.level} Level
                          </p>
                        </div>
                      </div>

                      <div className="space-y-3 mb-4">
                        <div className="flex items-center text-gray-600">
                          <BsGeoAlt className="mr-2 text-blue-500" />
                          <span>{career.location}</span>
                        </div>
                        <div className="flex items-center text-gray-600">
                          <HiOutlineBadgeCheck className="mr-2 text-green-500" />
                          <span>{career.type}</span>
                        </div>
                        <div className="flex items-center text-gray-600">
                          <BsCalendar className="mr-2 text-red-500" />
                          <span>
                            Expires:{" "}
                            {new Date(career.expiry_date).toLocaleDateString()}
                          </span>
                        </div>
                      </div>

                      <div className="mb-4">
                        <p
                          className={`text-gray-700 ${
                            expandedDescriptions[career.id]
                              ? "whitespace-pre-wrap break-words"
                              : "line-clamp-3"
                          }`}
                        >
                          {career.short_description || career.description}
                        </p>
                        {(career.short_description || career.description)
                          .length > 150 && (
                          <button
                            onClick={() => toggleDescription(career.id)}
                            className="text-blue-600 hover:text-blue-800 text-sm mt-2 font-medium"
                          >
                            {expandedDescriptions[career.id]
                              ? "Show Less"
                              : "Show More"}
                          </button>
                        )}
                      </div>

                      <div className="mb-4">
                        <div className="text-sm text-gray-700">
                          <strong>Required Documents:</strong>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {career.required_docs?.map((doc, docIndex) => (
                              <span
                                key={docIndex}
                                className="bg-blue-50 text-blue-600 px-2 py-1 rounded-full text-xs"
                              >
                                {doc}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500 font-bold">
                          {career.no} Openings Available
                        </span>
                        <button
                          onClick={() => setSelectedJob(career)}
                          className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-full text-center py-12">
                  <h3 className="text-2xl font-semibold text-gray-600">
                    No Job Openings Currently
                  </h3>
                  <p className="text-gray-500 mt-4">
                    Check back later for new opportunities.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Job Application Modal */}
      {selectedJob && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl shadow-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-2xl font-bold text-gray-800">
                Apply for {selectedJob.name}
              </h2>
              <button
                onClick={() => setSelectedJob(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <HiX size={24} />
              </button>
            </div>

            <form onSubmit={handleSubmitApplication} className="p-6 space-y-6">
              {/* Personal Information */}
              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Full Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={applicationForm.name}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.name
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-primary"
                      }`}
                  />
                  {formErrors.name && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.name}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={applicationForm.email}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.email
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-primary"
                      }`}
                  />
                  {formErrors.email && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.email}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Phone Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={applicationForm.phone}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.phone
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-primary"
                      }`}
                  />
                  {formErrors.phone && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.phone}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Address <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={applicationForm.address}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.address
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-primary"
                      }`}
                  />
                  {formErrors.address && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.address}
                    </p>
                  )}
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Education <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="education"
                    value={applicationForm.education}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.education
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-primary"
                      }`}
                  />
                  {formErrors.education && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.education}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Skills (comma-separated){" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="skills"
                    value={applicationForm.skills}
                    onChange={handleInputChange}
                    placeholder="e.g. JavaScript, React, Node.js"
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.skills
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-primary"
                      }`}
                  />
                  {formErrors.skills && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.skills}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Previous Work Experience{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="previousWorks"
                    value={applicationForm.previousWorks}
                    onChange={handleInputChange}
                    rows={4}
                    placeholder="Describe your previous work experiences"
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                      ${
                        formErrors.previousWorks
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-primary"
                      }`}
                  />
                  {formErrors.previousWorks && (
                    <p className="text-red-500 text-xs mt-1">
                      {formErrors.previousWorks}
                    </p>
                  )}
                </div>
              </div>

              {/* Required Documents Upload */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  Required Documents <span className="text-red-500">*</span>
                </h3>
                <p className="text-sm text-gray-600 mb-4">
                  Please upload the following documents:
                  <ul className="list-disc list-inside mt-2">
                    <li>File size must be less than 1MB</li>
                    <li>Accepted file types: PNG, JPEG, JPG</li>
                  </ul>
                </p>
                {selectedJob.required_docs.map((doc, index) => (
                  <div key={index} className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2 capitalize">
                      {doc} <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      name={doc}
                      required
                      onChange={(e) => handleDocumentUpload(doc, e)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    />
                  </div>
                ))}
              </div>

              {/* Submit Button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`bg-primary hover:bg-primary text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      <span className="ml-2">Submitting...</span>
                    </div>
                  ) : (
                    "Submit Application"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
