import React from "react";
import PageHeader from "../../components/PageHeader";

const TermsAndConditions = () => {
  const termsAndConditionsSections = [
    {
      title: "Overview",
      content: (
        <p className="text-lg">
          These Terms and Conditions govern your use of the SWI website and your
          participation in SWI programs and services.
        </p>
      ),
    },
    {
      title: "Acceptance of Terms",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          You agree to use our website in a lawful and responsible manner. You
          will not:
          <li className="text-lg flex-1">
            Use the website for any illegal or unauthorized purpose
          </li>
          <li className="text-lg flex-1">
            Attempt to gain unauthorized access to the website or our systems
          </li>
          <li className="text-lg flex-1">
            Interfere with the use of the website by others
          </li>
          <li className="text-lg flex-1">
            Transmit any viruses or other harmful code
          </li>
        </ul>
      ),
    },
    {
      title: "Participation in Programs and Services",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          <li className="text-lg flex-1">
            You agree to abide by all program rules and guidelines.
          </li>
          <li className="text-lg flex-1">
            You are responsible for your own safety and well-being during your
            participation in SWI programs and services.
          </li>
          <li className="text-lg flex-1">
            You agree to treat all SWI staff, participants, and partners with
            respect.
          </li>
        </ul>
      ),
    },
    {
      title: "Copyright and Intellectual Property",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          The content of our website and programs is protected by copyright and
          other intellectual property laws. You may not copy, distribute,
          modify, or use any of this content without our express written
          permission.
        </ul>
      ),
    },
    {
      title: "Disclaimer",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          SWI provides information and resources for educational purposes only.
          This information is not a substitute for professional advice. We make
          no warranties about the accuracy or completeness of the information we
          provide.
        </ul>
      ),
    },
    {
      title: "Limitation of Liability",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          SWI will not be liable for any damages arising from your use of our
          website or your participation in our programs and services.
        </ul>
      ),
    },
    {
      title: "Termination",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          SWI may terminate your access to our website or programs and services
          for any violation of these Terms and Conditions.
        </ul>
      ),
    },
    {
      title: "Governing Law",
      content: (
        <ul className="flex flex-col gap-4 list-disc pl-5">
          These Terms and Conditions will be governed by and construed in
          accordance with the laws of Nepal.
        </ul>
      ),
    },
    {
      title: "Entire Agreement",
      content: (
        <p className="text-lg">
          These Terms and Conditions constitute the entire agreement between you
          and SWI with respect to your use of our website and your participation
          in our programs and services.
        </p>
      ),
    },
  ];

  return (
    <div>
      <div className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center">
        <div className="absolute inset-0 bg-aboutbanner bg-top bg-cover bg-no-repeat" />
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Terms and Conditions"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },
            {
              name: "About Us",
              to: "/about",
            },
          ]}
        />
      </div>

      <div className="lg-container py-20">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-10">
          Terms and Conditions
        </h2>

        {termsAndConditionsSections.map((section, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl font-semibold text-gray-700 mb-4 border-b pb-2">
              {section.title}
            </h3>
            {section.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;
