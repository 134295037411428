import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./layout/Layout";
import About from "./pages/About";
import AnnouncementsIndex from "./pages/announcements";
import CareerDetail from "./pages/announcements/careers/CareerDetail";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Network from "./pages/networks/Network";
import EventDetail from "./pages/programs/events/EventDetail";
import ProgramIndex from "./pages/programs/Index";
import TrainingDetail from "./pages/programs/trainings/TrainingDetail";
import BlogDetail from "./pages/resources/blog/BlogDetail";
import ResourcesIndex from "./pages/resources/Index";
import ServicesIndex from "./pages/services/Index";
import PrivacyPolicy from "./sections/legal/PrivacyPolicy";
import TermsAndConditions from "./sections/legal/TermsAndConditions";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />

        <Route
          path="/contact"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />

        <Route
          path="/gallery/:category"
          element={
            <Layout>
              <Gallery />
            </Layout>
          }
        />

        <Route
          path="/resources/:category"
          element={
            <Layout>
              <ResourcesIndex />
            </Layout>
          }
        />

        <Route
          path="/programs/:category"
          element={
            <Layout>
              <ProgramIndex />
            </Layout>
          }
        />

        <Route
          path="/services/:category"
          element={
            <Layout>
              <ServicesIndex />
            </Layout>
          }
        />

        <Route
          path="/announcements/:category"
          element={
            <Layout>
              <AnnouncementsIndex />
            </Layout>
          }
        />

        <Route
          path="/programs/events/:slug"
          element={
            <Layout>
              <EventDetail />
            </Layout>
          }
        />

        <Route
          path="/programs/trainings/:slug"
          element={
            <Layout>
              <TrainingDetail />
            </Layout>
          }
        />

        <Route
          path="/resources/blogs/:slug"
          element={
            <Layout>
              <BlogDetail />
            </Layout>
          }
        />

        <Route
          path="/network/:category"
          element={
            <Layout>
              <Network />
            </Layout>
          }
        />
        <Route path="/careers/:id" element={<CareerDetail />} />
        <Route
          path="/privacy-policy"
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
