import { useState } from "react";
import { FaBars, FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import Logo from "./Logo";
import MobileNav from "./MobileNav";

const ContactBar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <div className="flex justify-between items-start pt-[0.5rem] h-[7rem] nav:h-[5rem] nav:pt-0 nav:items-center lg-container">
        <div className="flex justify-between w-full items-center">
          <Logo />
          <div className="md:flex gap-5 hidden">
            <div className="flex items-center gap-2">
              <div className="bg-primary p-2 rounded-full">
                <FaMapMarkerAlt className="w-5 h-5 text-white" />
              </div>
              <div>
                <p className="text-sm text-gray-600">Locate Address:</p>
                <p className="font-semibold">Nakhu, Lalitpur</p>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <div className="bg-primary p-2 rounded-full">
                <FaPhone className="w-5 h-5 text-white" />
              </div>
              <div>
                <p className="text-sm text-gray-600">Call us any time:</p>
                <p className="font-semibold">+977-1-5185315</p>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <div className="bg-primary p-2 rounded-full">
                <FaEnvelope className="w-5 h-5 text-white" />
              </div>
              <div>
                <p className="text-sm text-gray-600">Email us any time:</p>
                <a
                  href="mailto:info@swi-nepal.org"
                  className="font-semibold hover:underline"
                >
                  info@swi-nepal.org
                </a>
              </div>
            </div>
          </div>
          <button
            className="w-6 h-6 hidden nav:block"
            onClick={toggleMobileNav}
            aria-label="Toggle mobile navigation"
          >
            <FaBars className="w-full h-full" />
          </button>
        </div>
      </div>

      <MobileNav isOpen={isMobileNavOpen} onClose={toggleMobileNav} />
    </>
  );
};

export default ContactBar;
