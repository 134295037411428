import React from "react";
import { BsQuestionOctagon } from "react-icons/bs";
import { FaRegMap } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMailOutline } from "react-icons/md";
import { ContactCard } from "../../components/ContactCard";

const MapSection = () => {
  return (
    <div className="py-20">
      <div className="lg-container flex gap-10 rounded-2xl flex-col md:flex-row">
        {/* Contact Details */}
        <div className="bg-card flex flex-col gap-5 flex-1">
          <ContactCard
            icon={<FaRegMap size={40} color="white" />}
            title="Address"
            details="Nakhu, Lalitpur"
          />
          <ContactCard
            icon={<LuPhoneCall size={40} color="white" />}
            title="Phone"
            details="+977-1-5185315"
          />
          <ContactCard
            icon={<MdOutlineMailOutline size={40} color="white" />}
            title="Email"
            details="info@swi-nepal.org"
          />
          <ContactCard
            icon={<BsQuestionOctagon size={40} color="white" />}
            title="Have Questions?"
            details="Discover more by visiting us or joining our community"
          />
        </div>

        {/* Map */}
        <div className="bg-card flex-[2]">
          <iframe
            title="Map"
            className="rounded-md h-[27rem]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.519817975008!2d85.30120427531622!3d27.66387412740784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19d2deb30cdf%3A0x242b0168281aeaf6!2sSocial%20Work%20Institute!5e1!3m2!1sen!2snp!4v1732636215596!5m2!1sen!2snp"
            allowFullScreen=""
            loading="lazy"
            width={"100%"}
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MapSection;
