import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft, FaMapMarkerAlt, FaBriefcase, FaCalendar } from 'react-icons/fa';
import Loading from '../../../common/Loading';
import PageHeader from '../../../components/PageHeader';

const CareerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobDetail = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/careers/${id}`);
        setJob(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch job details');
        setLoading(false);
      }
    };

    fetchJobDetail();
  }, [id]);

  const handleApplyNow = () => {
    navigate('/careers', { state: { selectedJobId: id } });
  };

  if (loading) return <Loading />;
  if (error) return <div className="text-center text-red-500 mt-10">{error}</div>;
  if (!job) return null;

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <button 
          onClick={() => navigate('/careers')} 
          className="flex items-center text-blue-600 hover:text-blue-800 mb-6"
        >
          <FaArrowLeft className="mr-2" /> Back to All Jobs
        </button>

        <PageHeader 
          title={job.title} 
          description="Detailed job description and requirements" 
        />

        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">{job.title}</h2>
              <button 
                onClick={handleApplyNow}
                className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition"
              >
                Apply Now
              </button>
            </div>

            <div className="grid md:grid-cols-3 gap-4 mb-6">
              <div className="flex items-center">
                <FaMapMarkerAlt className="mr-2 text-blue-600" />
                <span>{job.location}</span>
              </div>
              <div className="flex items-center">
                <FaBriefcase className="mr-2 text-blue-600" />
                <span>{job.type}</span>
              </div>
              <div className="flex items-center">
                <FaCalendar className="mr-2 text-blue-600" />
                <span>Posted {new Date(job.created_at).toLocaleDateString()}</span>
              </div>
            </div>

            <div className="space-y-6">
              <section>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Job Description</h3>
                <div 
                  className="text-gray-700 leading-relaxed"
                  dangerouslySetInnerHTML={{ __html: job.description }}
                />
              </section>

              {job.requirements && (
                <section>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Requirements</h3>
                  <ul className="list-disc list-inside text-gray-700 space-y-2">
                    {job.requirements.map((req, index) => (
                      <li key={index}>{req}</li>
                    ))}
                  </ul>
                </section>
              )}

              {job.responsibilities && (
                <section>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Responsibilities</h3>
                  <ul className="list-disc list-inside text-gray-700 space-y-2">
                    {job.responsibilities.map((resp, index) => (
                      <li key={index}>{resp}</li>
                    ))}
                  </ul>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerDetail;
