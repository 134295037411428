import React from "react";
import { Link } from "react-router-dom";
const Logo = () => {
  return (
    <Link to="/" className="flex items-center">
      <img src="/logo.png" className="w-56" alt="swi-logo" />
    </Link>
  );
};

export default Logo;
