import React from "react";
import { BsLink45Deg } from "react-icons/bs";

export const LinkPreviewCard = ({
  name,
  link,
  description,
  thumbnailUrl = "https://via.placeholder.com/300x200?text=Link+Preview",
}) => {
  const handleCardClick = () => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      className="w-full max-w-sm bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1"
      onClick={handleCardClick}
    >
      {/* Thumbnail */}
      <div className="w-full h-48 overflow-hidden">
        <img
          src={thumbnailUrl}
          alt={name}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.target.src =
              "https://via.placeholder.com/300x200?text=Link+Preview";
          }}
        />
      </div>

      {/* Content area */}
      <div className="p-4">
        {/* Link title */}
        <h3 className="text-lg font-semibold text-gray-900 mb-2 truncate">
          {name}
        </h3>

        {/* Link URL */}
        <div className="flex items-center text-sm text-gray-500">
          <BsLink45Deg className="mr-2" />
          <span className="truncate max-w-full">{link}</span>
        </div>
      </div>
    </div>
  );
};

export const LinkPreviewCardVertical = ({
  name,
  link,
  description,
  thumbnailUrl = "https://via.placeholder.com/300x200?text=Link+Preview",
}) => {
  const handleCardClick = () => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      className="w-full bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1 flex"
      onClick={handleCardClick}
    >
      {/* Thumbnail */}
      <div className="w-32 h-32 flex-shrink-0 overflow-hidden">
        <img
          src={thumbnailUrl}
          alt={name}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.target.src =
              "https://via.placeholder.com/300x200?text=Link+Preview";
          }}
        />
      </div>

      {/* Content area */}
      <div className="p-4 flex-grow">
        {/* Link title */}
        <h3 className="text-lg font-semibold text-gray-900 mb-2 truncate">
          {name}
        </h3>

        {/* Description */}
        {description && (
          <p className="text-sm text-gray-600 mb-2 line-clamp-2">
            {description}
          </p>
        )}

        {/* Link URL */}
        <div className="flex items-center text-sm text-gray-500">
          <BsLink45Deg className="mr-2" />
          <span className="truncate max-w-full">{link}</span>
        </div>
      </div>
    </div>
  );
};
