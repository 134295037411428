import React, { useState } from "react";
import PageHeader from "../../components/PageHeader";

const TrainingFacilities = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const trainingImages = [
    "/training-facilities/training1.jpeg",
    "/training-facilities/training2.jpeg",
    "/training-facilities/training3.jpeg",
    "/training-facilities/training4.jpeg",
    "/training-facilities/training5.jpeg",
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <div className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center">
        <div className="absolute inset-0 bg-aboutbanner bg-center bg-cover bg-no-repeat" />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title=""
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },
            {
              name: "Training Facilities",
              to: "/services/training-facilities",
            },
          ]}
        />
      </div>

      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container flex flex-col gap-5">
          <section className="text-lg leading-relaxed">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">
              Residential Training Facilities to rent out at SWI
            </h1>
            <div className="space-y-6">
              <p>
                SWI has earthquake resilient training centers which includes 2
                separate buildings with area 8,108.03 square feet (752.76 Sq. M)
                in 9,925.25 square feet (921.91 Sq. M) lands. It was constructed
                in 2018. All the halls and rooms are well designed with fresh
                air circulation and sun lights. 21 toilets/attached bathrooms
                have been constructed in strategic points and separated to male
                and female. Bathrooms, toilets, training halls, meeting rooms
                are people with disability friendly. It has underground
                rainwater harvesting tank with 50,000 liters capacity.
              </p>
              <p>
                With 5.200 Kwp capacity, Photo Voltic (PV) solar power system
                has been installed at roof of the buildings. The PV system is
                connected in grid of Nepal Electricity Authority (NEA) and
                installed net metering. If the generated power is more than
                requirement, it automatically transfer to the grid which helps
                us to reduce the electricity bill. The system has been
                contributing to reduce 8 tons carbon per year.
              </p>
              <p>
                SWI training center has seriously considered safety and security
                of its users from different aspects. It has installed fire alarm
                system, smoke sensor, heat detector, earthquake alarm, call
                point, hooters and fire extinguisher. In addition, safe place
                and route to reach safe space have been identified and indicated
                at strategic points for emergency.
              </p>
              <p>
                It is located 600-meters south from Akantakuna, ring road. It
                has enough parking areas for two-wheeler and small
                four-wheelers. Open space is nearby training center for
                additional parking. SWI offer the following facilities to all
                development/humanitarian organizations, government agencies and
                private sectors.
              </p>
            </div>
          </section>

          <div className="flex items-start gap-8 md:flex-row flex-col mt-10">
            <section className="w-full">
              <h2 className="text-3xl font-bold text-gray-900 mb-2">
                Our Facilities
              </h2>
              <ul className="space-y-3 text-lg">
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Training halls
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Meeting rooms
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Accommodation
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Food and snacks
                  (Kitchen and dining hall)
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Internet
                  facilities/Wifi
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Computer lab
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> Training
                  workshop facilitation
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span>{" "}
                  Other/Logistical support
                </li>
              </ul>
            </section>

            <div className="w-full grid grid-cols-12 gap-4">
              <div
                className="col-span-12 md:col-span-8 row-span-2 cursor-pointer"
                onClick={() => handleImageClick(trainingImages[0])}
              >
                <div className="h-full w-full overflow-hidden rounded-lg">
                  <img
                    src={trainingImages[0]}
                    alt="Main Training Facility"
                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 grid grid-rows-2 gap-4">
                {[1, 2].map((index) => (
                  <div
                    key={index}
                    className="overflow-hidden rounded-lg cursor-pointer"
                    onClick={() => handleImageClick(trainingImages[index])}
                  >
                    <img
                      src={trainingImages[index]}
                      alt={`Training Facility ${index + 1}`}
                      className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                ))}
              </div>
              {[3, 4].map((index) => (
                <div
                  key={index}
                  className="col-span-12 md:col-span-6 overflow-hidden rounded-lg cursor-pointer h-[200px]"
                  onClick={() => handleImageClick(trainingImages[index])}
                >
                  <img
                    src={trainingImages[index]}
                    alt={`Training Facility ${index + 1}`}
                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Fullscreen Modal for Selected Image */}
      {selectedImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 p-4"
          onClick={closeModal}
        >
          <div className="relative max-w-6xl max-h-[90vh] w-full h-full">
            <img
              src={selectedImage}
              alt="Full size training facility"
              className="w-full h-full object-contain"
            />
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 m-4 text-white text-4xl hover:text-gray-300 z-60"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainingFacilities;
