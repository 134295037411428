import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FaHandshake, FaThermometerHalf } from "react-icons/fa";
import NormalHeader from "../../components/NormalHeader";
import { ThematicCard } from "../../components/ThematicCard";

gsap.registerPlugin(ScrollTrigger);

export default function ThematicArea() {
  return (
    <div className="bg-secondary relative  flex flex-col justify-center py-20">
      <div className="sm-container">
        <NormalHeader
          mb={false}
          title="Thematic area of swi"
          subTitle="Our Areas"
        />

        {/* Service Cards */}
        <div className="grid mt-5 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 mx-auto sm:grid-cols-2">
          <ThematicCard
            icon={
              <div className="rounded-full bg-[#E87032] w-40 aspect-square p-10">
                <img
                  src="/home/thematic/empower.webp"
                  alt="swi-library"
                  className="object-contain"
                />
              </div>
            }
            title="EMPOWERMENT AND CAPACITY BUILDING"
          />
          <ThematicCard
            icon={
              <div className="rounded-full bg-primary w-40 aspect-square p-10">
                <FaThermometerHalf color="white" size={80} />
              </div>
            }
            title="CLIMATE ACTION"
          />
          <ThematicCard
            icon={
              <div className="rounded-full bg-[#119ED5] w-40 aspect-square p-10">
                <FaHandshake color="white" size={80} />
              </div>
            }
            title="GLOABAL AND REGIONAL PARTNERSHIP"
          />
        </div>
      </div>
    </div>
  );
}
