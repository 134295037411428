import React from "react";
import NormalHeader from "../../components/NormalHeader";
import { Timeline } from "../../components/ui/Timeline";

const History = () => {
  const data = [
    {
      title: "1987",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Nepal Jesuit Social Work Training was established by Br. James F.
            Gates, SJ.
          </li>

          <li className="text-lg flex-1">
            St. Xavier's School granted permission to use the old barn house
            located within the school compound.
          </li>

          <li className="text-lg flex-1">
            First batch consisting of 10 participants attended the course from
            January, 1987 using one of St. Xavier School room as a training
            hall.
          </li>

          <li className="text-lg flex-1">
            Hundreds of volunteers, including attending participants helped to
            renovate the old barn house into an office complex.
          </li>
        </ul>
      ),
    },
    {
      title: "1988",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Operations started from the newly renovated office complex.
          </li>
          <li className="text-lg flex-1">
            Participants were recruited from rural areas for the first time.
          </li>
          <li className="text-lg flex-1">
            Community development project ideas were formulated.
          </li>
          <li className="text-lg flex-1">
            ACSW (Association of Certified Social Workers) a two year follow –
            up programme was initiated.
          </li>
          <li className="text-lg flex-1">
            Concept of field placement was implemented in the nine – month
            training programme.
          </li>
        </ul>
      ),
    },

    {
      title: "1989",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Two shifts of classes were conducted in the nine month training
            programme, one for rural participants and the other for urban
            participants.
          </li>

          <li className="text-lg flex-1">
            YET programme (Youth Environmental Therapy) was also initiated with
            the intention to help youths with social problems.
          </li>

          <li className="text-lg flex-1">
            Proposal for "Gobardiha Project" was accepted and work to initiate
            the project in Dang began. Sewing programme for women's group was
            established.
          </li>

          <li className="text-lg flex-1">
            First batch of ACSW members graduated and received certificates.
          </li>
        </ul>
      ),
    },

    {
      title: "1990",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            A separate building for the YET programme was rented.
          </li>

          <li className="text-lg flex-1">
            Two shifts of classes in the nine month training programme was
            discontinued.
          </li>

          <li className="text-lg flex-1">
            Child sponsorship programme (ESNAC) funded by Japanese sisters was
            handed over to the institute. A separate body was formed to
            implement this programme.
          </li>

          <li className="text-lg flex-1">
            Gobardiha Project was initiated in Gobardiha, Dang targeting local
            women to develop further local skills.
          </li>

          <li className="text-lg flex-1">
            SWI was exposed to the concept of Animation (Frierian Approach to
            development).
          </li>
        </ul>
      ),
    },

    {
      title: "1991",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            The institute planned to mobilize its alumni further in implementing
            grassroots level projects in different parts of Nepal.
          </li>
          <li className="text-lg flex-1">
            Short – term training programmes were initiated.
          </li>
        </ul>
      ),
    },

    {
      title: "1992",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Rural experience programmes for the nine month training course
            participants was implemented for the first time. The participants
            were sent for exposure trips to Nuwakot and India. The concept of
            Block Placement was developed.
          </li>

          <li className="text-lg flex-1">
            Talks about Bandipur project began.
          </li>

          <li className="text-lg flex-1">
            Small micro-projects mobilizing female alumni was initiated in
            Syanja, Baitadi and Okhaldunga.
          </li>
        </ul>
      ),
    },

    {
      title: "1993",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Br. James F. Gates, SJ was recalled to the United States by his
            province.
          </li>

          <li className="text-lg flex-1">
            The institute underwent a restructuring process.
          </li>

          <li className="text-lg flex-1">Gobardiha project was phased out.</li>

          <li className="text-lg flex-1">
            YET (Youth Environmental Therapy) programme was phased out.
          </li>

          <li className="text-lg flex-1">
            ESNAC programme became independent, now functioning as an
            independent NGO.
          </li>
        </ul>
      ),
    },

    {
      title: "1994",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            The institute was managed by purely Nepali Team consisting of 4
            staff members and one volunteer.
          </li>

          <li className="text-lg flex-1">
            Concept of block placement was fully developed and implemented.
          </li>

          <li className="text-lg flex-1">
            Talks about relocating the organization outside of St. Xavier's
            Compound began.
          </li>

          <li className="text-lg flex-1">
            Bandipur Animation Programme in Bandipur, Tanahu was initiated
            mobilizing three alumni residing there.
          </li>

          <li className="text-lg flex-1">The boy's hostel was relocated.</li>
        </ul>
      ),
    },

    {
      title: "1995",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            SWI moved out of St. Xavier's Compound to a new location in
            Dhobighat area.
          </li>

          <li className="text-lg flex-1">
            Two new staff members were hired, Training and Project Supervsor.
          </li>

          <li className="text-lg flex-1">The girl's hostel was relocated.</li>

          <li className="text-lg flex-1">
            Concept of block training in the nine month training programme was
            developed and implemented.
          </li>

          <li className="text-lg flex-1">
            Independent paper (IS) study was also introduced into the nine month
            training curriculum.
          </li>
        </ul>
      ),
    },

    {
      title: "1996",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Urhari Animation Programme was initiated in Urhari VDC, Tulsipur,
            Dang.
          </li>

          <li className="text-lg flex-1">
            An assistant to help in office works was hired.
          </li>
        </ul>
      ),
    },

    {
      title: "1997",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Project Supervisor resigned from her post.
          </li>

          <li className="text-lg flex-1">
            SWI programmes consisted of the following:
            <ul className="list-decimal list-inside">
              <li className="text-lg flex-1">
                Nine month training programme for social workers
              </li>

              <li className="text-lg flex-1">
                Short – Term training programme
              </li>

              <li className="text-lg flex-1">Bandipur Animation Programme</li>

              <li className="text-lg flex-1">Urhari Animation Programme </li>

              <li className="text-lg flex-1">
                One of SWI's project staff, Napi Lal Choudhary, of Dang died of
                a snake bite.
              </li>
            </ul>
          </li>
        </ul>
      ),
    },
    {
      title: "1998",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Concept of Micro Project was developed
          </li>

          <li className="text-lg flex-1">SWI was relocated to Jhamisikhel</li>

          <li className="text-lg flex-1">
            A long term plan to register the organization as an independent NGO
            was made.
          </li>

          <li className="text-lg flex-1">
            Two new staff members, Project supervisor and Hotel in Charge, were
            hired.
          </li>
        </ul>
      ),
    },
    {
      title: "1999",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            SWI operations began from the new building
          </li>

          <li className="text-lg flex-1">
            Micro – Project programme proposal was accepted and initial works
            began.
          </li>
        </ul>
      ),
    },

    {
      title: "2000",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            SWI made plans to relocate the organization with the intention of
            having the hostels in the same complex.
          </li>

          <li className="text-lg flex-1">
            SWI was registered as an independent NGO.
          </li>

          <li className="text-lg flex-1">
            Four areas were chosen for Micro-project, Baitadi, Dadheldhura,
            Salyan and Bardia and implementation of the programmes began.
          </li>

          <li className="text-lg flex-1">
            SWI moved to a new location in Khumaltar in December.
          </li>
        </ul>
      ),
    },

    {
      title: "2001",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            SWI operated for the first time in a complex consisting of the
            office building, hostels and training room in the same compound.
          </li>

          <li className="text-lg flex-1">
            Micro-project programme was initiated.
          </li>
        </ul>
      ),
    },

    {
      title: "2002",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            A new assistant, Ganesh Bikram Khattri was hired.
          </li>

          <li className="text-lg flex-1">SWI experienced a flood.</li>
        </ul>
      ),
    },
    {
      title: "2003",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Jugeda Animation Programme approved and initiated in west Nepal.
          </li>
        </ul>
      ),
    },

    {
      title: "2005",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">Registered as an Independent NGO.</li>
        </ul>
      ),
    },

    {
      title: "2007",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Kailali Regional office established.
          </li>
        </ul>
      ),
    },

    {
      title: "2008",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Baitadi Project started at Baitadi Branch.
          </li>
        </ul>
      ),
    },

    {
      title: "2010",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Development of Diploma in Social Work Training together with CTEVT
            (3-year Training Curriculum).
          </li>
        </ul>
      ),
    },

    {
      title: "2013",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Research conducted on child abandonment to develop training
            curriculum for Child Protection workers.
          </li>
        </ul>
      ),
    },

    {
      title: "2014",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Piloted the Child Protection Worker&nbsp;s training program.
          </li>
        </ul>
      ),
    },

    {
      title: "2015",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Affiliated with CTEVT to conduct the Child protection worker&nbsp;s
            Training as a Vocational training.
          </li>

          <li className="text-lg flex-1">
            Earthquake moved us to Jawalakhel from Satdobato and then Nakhu.
          </li>
        </ul>
      ),
    },
    {
      title: "2018",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Development of Strategy including three themes such as Training,
            Integrated Community Development and Network and advocacy.
          </li>
        </ul>
      ),
    },

    {
      title: "2019",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            SWI moved to its own training center at Nakhu.
          </li>
          <li className="text-lg flex-1">
            Development of Curriculum for Social Worker.
          </li>
        </ul>
      ),
    },

    {
      title: "2023",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Expanded the Karnali Provincial office at Surkhet.
          </li>
          <li className="text-lg flex-1">
            Held a National Youth Conference on Agroecological.
          </li>
        </ul>
      ),
    },

    {
      title: "2024",
      content: (
        <ul className="flex flex-col gap-4 list-disc">
          <li className="text-lg flex-1">
            Hosted a Regional Youth Conference.
          </li>
        </ul>
      ),
    },
  ];

  return (
    <div className="bg-white flex flex-col justify-center py-20">
      <div className="sm-container">
        <NormalHeader
          animate={false}
          subTitle="Our Inspiring Journey"
          title="FROM HUMBLE BEGINNINGS TO MEANINGFUL IMPACT"
        />
        <Timeline data={data} />
      </div>
    </div>
  );
};

export default History;
