import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import { GalleryCard } from "../../components/GalleryCard";
import NormalHeader from "../../components/NormalHeader";
import { useCommonContext } from "../../hooks/useCommonContext";

const GallerySection = () => {
  const { categories } = useCommonContext();
  const [galleries, setGalleries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { category } = useParams();

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        setLoading(true);

        // Step 2: Fetch clients for each categoryId
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/galleries?category=${category}`
        );

        setGalleries(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGalleries();
  }, [category]);

  return (
    <>
      {loading && <Loading />}
      {error && <p className="text-red-500">{error}</p>}

      {!loading && !error && (
        <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
          <div className="lg-container">
            <NormalHeader
              animate={false}
              title={`${
                categories?.data?.find((c) => c?.slug === category)?.name
              }`}
              subTitle="Gallery"
            />

            {galleries?.data?.data?.length ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {galleries?.data?.data?.map((image, index) => (
                  <GalleryCard key={index} data={image} />
                ))}
              </div>
            ) : (
              <div className="font-title flex justify-center uppercase">
                No Gallery{" "}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GallerySection;
