import axios from "axios";
import React, { useState } from "react";
import * as Yup from "yup";

const DonationForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    success: false,
    error: false,
    message: "",
  });

  // Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    phone: Yup.string().required("Phone number is required"),
    message: Yup.string()
      .optional()
      .max(500, "Message must not exceed 500 characters"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear specific field error when user starts typing
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      // Validate the form
      await validationSchema.validate(formData, { abortEarly: false });

      // Create FormData for submission
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("email", formData.email);
      submitData.append("phone", formData.phone);
      submitData.append("message", formData.message || "");

      // Submit donation form
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/donations`,
        submitData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Reset form and show success message
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setFormErrors({});
      setSubmitStatus({
        success: true,
        error: false,
        message: "Thank you for your donation! We'll get back to you soon.",
      });

      // Close modal after a short delay
      setTimeout(onClose, 2000);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Handle validation errors
        const errors = {};
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
        setFormErrors(errors);
      } else {
        // Handle submission errors
        console.error("Donation form submission error:", error);
        setSubmitStatus({
          success: false,
          error: true,
          message:
            error?.response?.data?.message ||
            "Failed to submit donation. Please try again.",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-2xl w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
        >
          ✕
        </button>
        <h2 className="text-2xl font-bold mb-6 text-center">
          Support Our Mission
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Your Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              className={`mt-1 block w-full px-4 py-2 rounded-md border shadow-sm 
                ${
                  formErrors.name
                    ? "border-red-500 focus:ring-red-500"
                    : "focus:ring-primary focus:border-primary"
                }`}
            />
            {formErrors.name && (
              <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
              className={`mt-1 block w-full px-4 py-2 rounded-md border shadow-sm 
                ${
                  formErrors.email
                    ? "border-red-500 focus:ring-red-500"
                    : "focus:ring-primary focus:border-primary"
                }`}
            />
            {formErrors.email && (
              <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number <span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone Number"
              className={`mt-1 block w-full px-4 py-2 rounded-md border shadow-sm 
                ${
                  formErrors.phone
                    ? "border-red-500 focus:ring-red-500"
                    : "focus:ring-primary focus:border-primary"
                }`}
            />
            {formErrors.phone && (
              <p className="text-red-500 text-xs mt-1">{formErrors.phone}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              Additional Message (Optional)
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Your message (optional)"
              rows="3"
              className={`mt-1 block w-full px-4 py-2 rounded-md border shadow-sm 
                ${
                  formErrors.message
                    ? "border-red-500 focus:ring-red-500"
                    : "focus:ring-primary focus:border-primary"
                }`}
            ></textarea>
            {formErrors.message && (
              <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>
            )}
          </div>

          {submitStatus.success && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              {submitStatus.message}
            </div>
          )}

          {submitStatus.error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              {submitStatus.message}
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-primary text-white py-3 rounded-md hover:bg-primary transition ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Submitting...</span>
              </div>
            ) : (
              "Submit Donation"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DonationForm;
