/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { BiSend } from "react-icons/bi";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram, FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom";
import DonationForm from "./DonationForm";

const Footer = () => {
  const [isDonationModalOpen, setIsDonationModalOpen] = useState(false);

  return (
    <>
      <footer className="bg-gray-900 text-white pt-10 pb-5">
        <div className="lg-container flex flex-col gap-12">
          {/* Newsletter Section */}
          <div className="">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
              <div className="flex-1">
                <h3 className="text-2xl font-semibold">
                  Subscribe To Our Newsletter
                </h3>
                <p className="text-gray-400">
                  Regular inspections and feedback mechanisms
                </p>
              </div>
              <div className="flex md:flex-row items-center justify-center md:justify-start gap-3">
                <input
                  type="email"
                  placeholder="Enter Email Address"
                  className="w-full md:w-auto px-4 py-2 flex-1 rounded-full text-gray-800 focus:outline-none"
                />
                <button className="px-4 py-2 bg-secondary text-black rounded-full hover:bg-lightBg transition duration-300">
                  <BiSend size={20} className="-rotate-45" />
                </button>
              </div>
            </div>
          </div>

          {/* Main Footer Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {/* About Section */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg font-bold">Social Work Institute</h4>
              <p className="text-gray-400">
                Empowering the marginalized people to create a inclusive society
                with our values
              </p>
              <button
                onClick={() => setIsDonationModalOpen(true)}
                className="px-4 py-2 bg-primary rounded-full w-fit"
              >
                Donate Now
              </button>
            </div>

            {/* Quick Links */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg font-bold">Quick Links</h4>
              <ul className="space-y-2">
                {[
                  { name: "Home", link: "/" },
                  { name: "About Us", link: "/about" },
                  { name: "Our Blogs", link: "/resources/blogs" },
                  { name: "Privacy Policy", link: "/privacy-policy" },
                  {
                    name: "Terms and Conditions",
                    link: "/terms-and-conditions",
                  },
                  { name: "Contact Us", link: "/contact" },
                ].map(({ name, link }, index) => (
                  <li
                    key={index}
                    className="text-gray-400 hover:text-white transition duration-300"
                  >
                    <Link to={link}>{name}</Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Our Service */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg font-bold">Our Service</h4>
              <ul className="space-y-2">
                {[
                  {
                    name: "Training Facilities",
                    link: "/services/training-facilities",
                  },
                  {
                    name: "Seminar Workshop Training",
                    link: "/services/seminar-workshop-training",
                  },
                  {
                    name: "Knowledge Exchange",
                    link: "/services/knowledge-exchange",
                  },
                  { name: "Excursion", link: "/services/excursion" },
                ].map((service, index) => (
                  <li
                    key={index}
                    className="text-gray-400 hover:text-white transition duration-300"
                  >
                    <Link to={service.link} className="flex items-center">
                      <span className="mr-2">→</span> {service.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Contact Us */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg font-bold">Contact Us</h4>
              <ul className="space-y-4">
                <li className="text-gray-400">
                  <span className="block font-semibold text-white">
                    Call us any time:
                  </span>
                  +977-1-5185315
                </li>
                <li className="text-gray-400">
                  <span className="block font-semibold text-white">
                    Email us any time:
                  </span>
                  info@swi-nepal.org
                </li>
              </ul>
              <div className="flex space-x-4">
                {[
                  {
                    label: "facebook",
                    icon: <FaFacebook />,
                    link: "https://www.facebook.com/profile.php?id=100092171194580",
                  },
                  {
                    label: "linkedin",
                    icon: <FaLinkedin />,
                    link: "https://www.facebook.com/profile.php?id=100092171194580",
                  },
                  {
                    label: "twitter",
                    icon: <BsTwitterX />,
                    link: "https://www.facebook.com/profile.php?id=100092171194580",
                  },
                  {
                    label: "instagram",
                    icon: <FaInstagram />,
                    link: "https://www.facebook.com/profile.php?id=100092171194580",
                  },
                ].map((platform, index) => (
                  <a
                    key={index}
                    href={platform.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 flex items-center justify-center bg-gray-800 rounded-full hover:bg-gray-700 transition duration-300"
                  >
                    <span className="sr-only">{platform.label}</span>
                    {platform.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="border-t border-gray-800 pt-4">
            <div className="text-center">
              <p className="text-white">
                Copyright 2023 Social Work Institute | All Rights Reserved.
                Designed and Developed by{" "}
                <a href="https://apptechnologies.co" target="__blank">
                  APP TECHNOLOGIES PVT LTD
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      <DonationForm
        isOpen={isDonationModalOpen}
        onClose={() => setIsDonationModalOpen(false)}
      />
    </>
  );
};

export default Footer;
