/* eslint-disable jsx-a11y/img-redundant-alt */
import * as cheerio from "cheerio";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Dialog } from "./ui/Dialog";

export const GalleryCard = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const cardRef = useRef(null);
  const modalRef = useRef(null);

  const desc = cheerio.load(data?.description || "")?.text();

  // Ensure images is always an array, even if a single image
  const images = Array.isArray(data?.images)
    ? data?.images
    : [data?.image].filter(Boolean);

  // Extract image URLs, handling both string and object cases
  const imageUrls = images
    .map((img) => (typeof img === "object" && img !== null ? img.name : img))
    .filter(Boolean);

  // Fallback image or placeholder
  const backgroundImage =
    imageUrls.length > 0 ? imageUrls[0] : "/path/to/placeholder-image.jpg";

  // Add a check to prevent opening dialog if no images
  const handleOpenDialog = () => {
    if (imageUrls.length > 0) {
      setIsOpen(true);
    }
  };

  const handleImageClick = (url, index) => {
    setCurrentImageIndex(index);
    setFullscreenImage(url);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  // Custom Carousel Navigation Functions
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  // Keyboard Navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isOpen) return;

      if (event.key === "ArrowRight") {
        nextImage();
      } else if (event.key === "ArrowLeft") {
        prevImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, imageUrls.length]);

  // Truncate description if it's too long
  const truncateDescription = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  // Handle View More click
  const handleViewMore = () => {
    setIsDescriptionExpanded(true);
    setIsOpen(true);
  };

  return (
    <>
      <div className="w-full">
        <div
          ref={cardRef}
          className="relative border-red-600 w-full aspect-[4/5] rounded-[2rem] overflow-hidden group"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#048642]/30 to-[#048642]/80 pointer-events-none" />

          {/* Blurred Overlay */}
          {/* <div className="absolute inset-0 backdrop-blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" /> */}

          {/* Animated Description Overlay */}
          {/* <div
            className="absolute bottom-0 inset-x-0 top-full group-hover:top-0 transition-all duration-500 ease-in-out bg-black/50 flex items-end p-4"
            style={{ backdropFilter: "blur(10px)" }}
          >
            <div className="p-4 rounded-lg h-[100%] sticky-scroll overflow-scroll overflow-x-hidden w-full">
              <p
                className="text-white"
                dangerouslySetInnerHTML={{ __html: truncateDescription(desc) }}
              />
              {data?.description && data?.description.length > 100 && (
                <button
                  onClick={handleViewMore}
                  className="text-white underline mt-2 text-sm"
                >
                  View More
                </button>
              )}
            </div>
          </div> */}

          {/* Content */}
          <div className="absolute bottom-0 left-0 p-8 space-y-2 pointer-events-none">
            <h2 className="text-base font-bold text-white">
              {moment(data?.created_at).format("MMM DD, YYYY")}
            </h2>

            <h2 className="text-2xl md:text-3xl font-bold text-white">
              {data?.name}
            </h2>
          </div>

          {/* Arrow Button */}
          <button
            className="absolute bg-white flex justify-center items-center top-6 right-6 w-12 h-12 rounded-full transition-transform group-hover:scale-105"
            onClick={handleOpenDialog}
          >
            <BsArrowRight className="w-6 h-6" />
          </button>
        </div>
      </div>

      <Dialog
        open={isOpen}
        onOpenChange={setIsOpen}
        className="w-full p-0 h-screen"
      >
        <div ref={modalRef} className="w-full h-full relative">
          {fullscreenImage ? (
            <div 
              className="fixed inset-0 z-50 bg-black bg-opacity-90 flex items-center justify-center p-8"
              onClick={closeFullscreenImage}
            >
              <img
                src={fullscreenImage}
                className="max-w-full max-h-full object-contain"
                alt="Fullscreen view"
              />
              <button 
                onClick={closeFullscreenImage}
                className="absolute top-4 right-4 text-white text-4xl"
              >
                ×
              </button>
            </div>
          ) : isDescriptionExpanded ? (
            <div className="h-full w-full overflow-auto p-8">
              <div className="max-w-2xl mx-auto">
                <h2 className="text-2xl font-bold mb-4">{data?.name}</h2>
                <div
                  className="text-lg"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </div>
            </div>
          ) : imageUrls?.length > 0 ? (
            <div className="h-full w-full p-8 overflow-auto">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {imageUrls.map((url, index) => (
                  <div
                    key={index}
                    className="aspect-square overflow-hidden transition-transform duration-300 hover:scale-105"
                    onClick={() => handleImageClick(url, index)}
                  >
                    <img
                      src={url}
                      className="w-full h-full object-cover"
                      alt={`Gallery Image ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </Dialog>

      {/* Fullscreen Image Modal (outside Dialog) */}
      {fullscreenImage && (
        <div 
          className="fixed inset-0 z-50 bg-black bg-opacity-90 flex items-center justify-center p-8"
          onClick={closeFullscreenImage}
        >
          <img
            src={fullscreenImage}
            className="max-w-full max-h-full object-contain"
            alt="Fullscreen view"
          />
          <button 
            onClick={closeFullscreenImage}
            className="absolute top-4 right-4 text-white text-4xl"
          >
            ×
          </button>
        </div>
      )}
    </>
  );
};
