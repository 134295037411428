import axios from "axios";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from "react";
import Loading from "../../common/Loading";
import { LinkPreviewCard } from "../../components/LinkPreviewCard";
import PageHeader from "../../components/PageHeader";
import { useCommonContext } from "../../hooks/useCommonContext";

gsap.registerPlugin(ScrollTrigger);

const Links = () => {
  const { categories } = useCommonContext();
  const bannerRef = useRef(null);
  const backgroundRef = useRef(null);

  const [links, setLinks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        setLoading(true);

        // Step 2: Fetch clients for each categoryId
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/blogs`,
          {
            params: {
              category_id: categories?.data?.find(
                (cat) => cat?.slug === "links"
              )?.id,
            },
          }
        );

        setLinks(response.data.data[0].data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLinks();
  }, [categories?.data]);

  useEffect(() => {
    // Parallax effect for the banner background image
    if (backgroundRef.current && bannerRef.current) {
      gsap.to(backgroundRef.current, {
        yPercent: -10, // Faster upward scroll for the background
        ease: "none",
        scrollTrigger: {
          trigger: bannerRef.current,
          start: "top top",
          end: "bottom top",
          scrub: true, // Smooth scrolling sync
        },
      });
    }
  }, []);

  return (
    <div>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-aboutbanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Links"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Links",
              to: "/resources/links",
            },
          ]}
        />
      </div>
      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container">
          {/* <NormalHeader
            animate={false}
            title="Check the Forms & Formats for downloads!"
            subTitle="Forms and Formats"
          /> */}

          {loading && <Loading />}
          {error && <p className="text-red-500">{error}</p>}

          {/* {!loading &&
            !error &&
            (links?.data?.[0]?.data?.length ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {links?.data?.[0]?.data?.map((data, index) => (
                  <TrainingCard key={index} data={data} />
                ))}
              </div>
            ) : (
              <div className="font-title uppercase w-full flex justify-center">
                No Links
              </div>
            ))} */}

          {links?.length ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
              {links?.map((data, index) => (
                <LinkPreviewCard
                  key={index}
                  {...data}
                  link={data?.description}
                  thumbnailUrl={data?.photo}
                />
              ))}
            </div>
          ) : (
            <div className="col-span-full text-center py-12">
              <h3 className="text-2xl font-semibold text-gray-600">
                No Links Currently
              </h3>
              <p className="text-gray-500 mt-4">
                Check back later for new links.
              </p>
            </div>
          )}
        </div>
      </div>{" "}
    </div>
  );
};

export default Links;
