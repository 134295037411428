import axios from "axios";
import React, { useState } from "react";
import * as Yup from "yup";
import NormalHeader from "../../components/NormalHeader";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    message: Yup.string()
      .required("Message is required")
      .min(10, "Message must be at least 10 characters")
      .max(500, "Message must not exceed 500 characters"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear specific field error when user starts typing
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsSubmitting(true);
      
      // Validate the form
      await validationSchema.validate(formData, { abortEarly: false });
      
      // Create FormData for submission
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("email", formData.email);
      submitData.append("phone", formData.phone);
      submitData.append("message", formData.message);

      // Submit contact form
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contacts`,
        submitData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Reset form and show success message
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setFormErrors({});
      setSubmitStatus({
        success: true,
        error: false,
        message: "Your message has been sent successfully!",
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Handle validation errors
        const errors = {};
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
        setFormErrors(errors);
      } else {
        // Handle submission errors
        console.error("Contact form submission error:", error);
        setSubmitStatus({
          success: false,
          error: true,
          message: 
            error?.response?.data?.message || 
            "Failed to send message. Please try again.",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="py-20 bg-white">
      <NormalHeader
        animate={true}
        subTitle="Contact with us"
        title="Get in touch with us & stay updated"
      />

      <div className="lg-container flex gap-10 rounded-2xl flex-col md:flex-row">
        {/* Contact Details */}
        <div className="bg-card flex flex-col md:flex-row gap-10 flex-1 items-center justify-center">
          <div className="flex-1 hidden md:block">
            <img
              className="w-full aspect-square object-contain rounded-2xl"
              src="/contact/contact.webp"
              alt=""
              style={{
                mixBlendMode: "multiply",
              }}
            />
          </div>
          {/* Right Section - Form */}
          <div className="flex-1 h-fit w-full">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Your Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your Name"
                  className={`mt-1 block w-full px-4 py-3 rounded-full bg-transparent border shadow-sm 
                    ${
                      formErrors.name
                        ? "border-red-500 focus:ring-red-500"
                        : "focus:ring-green-500 focus:border-green-500"
                    }`}
                />
                {formErrors.name && (
                  <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email Address"
                  className={`mt-1 block w-full px-4 py-3 rounded-full bg-transparent border shadow-sm 
                    ${
                      formErrors.email
                        ? "border-red-500 focus:ring-red-500"
                        : "focus:ring-green-500 focus:border-green-500"
                    }`}
                />
                {formErrors.email && (
                  <p className="text-red-500 text-xs mt-1">
                    {formErrors.email}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone Number"
                  className={`mt-1 block w-full px-4 py-3 rounded-full bg-transparent border shadow-sm 
                    ${
                      formErrors.phone
                        ? "border-red-500 focus:ring-red-500"
                        : "focus:ring-green-500 focus:border-green-500"
                    }`}
                />
                {formErrors.phone && (
                  <p className="text-red-500 text-xs mt-1">
                    {formErrors.phone}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type Your Message <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Type Your Message"
                  rows="4"
                  className={`mt-1 block w-full px-4 py-3 rounded-xl bg-transparent border shadow-sm 
                    ${
                      formErrors.message
                        ? "border-red-500 focus:ring-red-500"
                        : "focus:ring-green-500 focus:border-green-500"
                    }`}
                ></textarea>
                {formErrors.message && (
                  <p className="text-red-500 text-xs mt-1">
                    {formErrors.message}
                  </p>
                )}
              </div>

              {submitStatus.success && (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  {submitStatus.message}
                </div>
              )}

              {submitStatus.error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  {submitStatus.message}
                </div>
              )}

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-fit rounded-full py-3 px-6 bg-green-600 text-white text-lg font-semibold shadow hover:bg-green-700 transition ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 text-white mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span>Sending...</span>
                  </div>
                ) : (
                  "Send a Message"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
