import React from "react";
import NormalHeader from "../../components/NormalHeader";
import { StickyScroll } from "../../components/ui/StickyScroll";

const Mission = () => {
  const content = [
    {
      title: "Our Mission",
      description:
        "To empower people that are disadvantaged due to cast, gender, occupation or the place they live. We support them in their strive for a self-determined, active and dignified live",
      content: (
        <img
          src="/about/mission.jpg"
          alt=""
          className="aspect-square object-cover"
        />
      ),
    },
    {
      title: "Our vision",
      description: "Create a inclusive society in Nepal.",
      content: (
        <img
          src="/about/vision.jpg"
          alt=""
          className="aspect-square object-cover"
        />
      ),
    },
    {
      title: "OUR GOALS",
      description:
        "Marginalised people in Nepal have improved their socio-economic living conditions More professionally trained genuine young social workers serve in marginalised communities Marginalised communities increasingly own their development processes and improve their livelihood systems Practitioners of community-led development processes learn on and lobby jointly for common concerns in existing and new networks.",
      content: (
        <img
          src="/about/goal.jpg"
          alt=""
          className="aspect-square object-cover"
        />
      ),
    },
    {
      title: "OUR VALUES",
      description: (
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 flex-col">
            <strong>RESPECT: </strong>
            <p>
              Treat every person with full respect, acknowledging each ones
              specific condition
            </p>
          </div>

          <div className="flex gap-1 flex-col">
            <strong>SOCIAL JUSTICE: </strong>
            <p>
              Believe that everyone deserves equal economic, political and
              social rights and opportunities
            </p>
          </div>

          <div className="flex gap-1 flex-col">
            <strong>EMPATHY: </strong>
            <p>
              Listen to each with a positive inner attitude and without
              prejudices
            </p>
          </div>

          <div className="flex gap-1 flex-col">
            <strong>COMPETENCE: </strong>
            <p>
              Commit ourselves to provide professional services but at the same
              time acknowledge each ones personal needs and limitations
            </p>
          </div>

          <div className="flex gap-1 flex-col">
            <strong>INTEGRITY: </strong>
            <p>
              Treat every person with full respect, acknowledging each ones
              specific condition
            </p>
          </div>

          <div className="flex gap-1 flex-col">
            <strong>RESPECT: </strong>
            <p>
              Follow our vision and mission honestly and in a transparent way
            </p>
          </div>
        </div>
      ),
      content: (
        <img
          src="/about/values.jpeg"
          alt=""
          className="aspect-square object-cover"
        />
      ),
    },
  ];

  return (
    <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
      <div className="sm-container">
        <NormalHeader
          animate={false}
          subTitle="Are you ready to explore"
          title="We are founded to empower the humanity"
        />
        <StickyScroll content={content} />
      </div>
    </div>
  );
};

export default Mission;
