import React from "react";
import PageHeader from "../../components/PageHeader";

const KnowledgeExchange = () => {
  return (
    <>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-aboutbanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Knowledge Exchange"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Knowledge Exchange and Other Facilities",
              to: "/services/knowledge-exchange-and-other-facilities",
            },
          ]}
        />
      </div>

      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container flex flex-col gap-5">
          <section className="text-lg leading-relaxed">
            <ul className="list-decimal list-inside">
              <li className="text-3xl font-bold text-gray-900 mb-2">
                Knowledge exchange and collaborative efforts of SWI
              </li>

              <div className="space-y-6 ml-4">
                At SWI, we firmly believe that collaboration is the cornerstone
                of addressing societal challenges and creating lasting
                solutions. By joining forces with like-minded organizations, we
                amplify our impact and work more effectively toward common
                goals. To this end, we regularly organize programs and
                initiatives in partnership with organizations that share our
                vision and focus on similar issues. Our key areas of
                collaboration include:
              </div>

              <ul className="ml-4 mt-4">
                <li className="text-2xl font-bold text-gray-900 mb-2">
                  1.1 Joint Research Initiatives
                </li>

                <div className="space-y-6 ml-4 mt-4">
                  <p>
                    Research forms a critical part of our work in understanding
                    and addressing complex societal problems. Recently, we
                    collaborated with FIAN Nepal to conduct an in-depth study on
                    the status of the implementation of the UN Declaration on
                    the Rights of Peasants and Other People Working in Rural
                    Areas (UNDROP) in Nepal. This research combined insights
                    from both primary and secondary sources, offering a
                    comprehensive overview of the progress and challenges in
                    this area.
                  </p>
                </div>

                <li className="text-2xl font-bold text-gray-900 mb-2 mt-4">
                  1.2 Active Participation in Alliances and Networks
                </li>

                <div className="space-y-6 ml-4 mt-4">
                  <p>
                    We are proud to be an active member of various networks,
                    alliances, and consortiums that address pressing social
                    issues. As a member of groups like the Child Network, NGO
                    Federation, and other consortiums, we contribute to and
                    participate in joint programs and campaigns. These
                    initiatives tackle contemporary challenges, fostering
                    collaboration among stakeholders to bring about meaningful
                    change.
                  </p>
                </div>
              </ul>

              <li className="text-3xl font-bold text-gray-900 mb-2 mt-8">
                Other services we provide
              </li>

              <ul className="ml-4 mt-4">
                <li className="text-2xl font-bold text-gray-900 mb-2">
                  2.1 Joint Research Initiatives
                </li>

                <div className="space-y-6 ml-4 mt-4">
                  <p>
                    Leveraging our expertise, SWI provides consultancy services
                    to support organizations in enhancing their operational
                    efficiency and ensuring adherence to social accountability
                    standards. Our consultancy work includes:
                  </p>
                </div>

                <li className="text-2xl font-bold text-gray-900 mb-2 ml-8 mt-4">
                  2.1.1 Training Facilitation
                </li>
                <div className="ml-8">
                  We design and deliver customized training programs to equip
                  participants with the knowledge and skills needed to address
                  various social and organizational challenges.
                </div>

                <li className="text-2xl font-bold text-gray-900 mb-2 ml-8 mt-4">
                  2.1.2 Social Audits
                </li>
                <div className="ml-8">
                  We assist organizations in conducting social audits, providing
                  an independent and systematic review of their initiatives to
                  ensure transparency, accountability, and community impact.
                </div>

                <li className="text-2xl font-bold text-gray-900 mb-2 ml-8 mt-4">
                  2.1.3 Drafting Child Safeguarding Policies
                </li>
                <div className="ml-8">
                  Recognizing the importance of child protection, we offer
                  consultancy services to help organizations develop robust
                  child safeguarding policies, ensuring a safe and nurturing
                  environment for children under their care.
                </div>
              </ul>

              <li className="text-3xl font-bold text-gray-900 mb-2 mt-4">
                Knowledge Exchange and Capacity Building
              </li>
              <div className="mt-4">
                At SWI, we actively promote knowledge exchange by organizing
                training sessions for network members and partner organizations.
                These trainings serve as platforms for learning, collaboration,
                and capacity building across various areas.
              </div>

              <ul>
                <li className="text-2xl font-bold text-gray-900 mb-2 ml-4 mt-4">
                  3.1 Field and Excursion Visits
                </li>
                <div>
                  We host field visits and excursions to promote agroecology and
                  sustainable practices. These visits offer participants
                  hands-on learning opportunities and include:
                </div>
              </ul>
              <ul className="ml-8 list-disc">
                <li>
                  Showcasing community seed banks and biodiversity promotion
                  initiatives.
                </li>
                <li>
                  Promoting constitutional literacy among children to raise
                  awareness of their rights.
                </li>
                <li>
                  Encouraging the expansion of community-based youth networks to
                  advocate for and practice agroecology.
                </li>
              </ul>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default KnowledgeExchange;
