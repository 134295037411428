import ContactBar from "./ContactBar";
import NavMenu from "./NavMenu";

const Header = () => {
  return (
    <header className="relative">
      <ContactBar />
      <div className="bg-primary nav:hidden shadow-2xl absolute z-10 left-0 right-0 rounded-full h-[4rem] flex items-center lg-container translate-y-[-50%] px-5">
        <NavMenu />
      </div>
    </header>
  );
};

export default Header;
