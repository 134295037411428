import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import PageHeader from "../components/PageHeader";
import CountSection from "../sections/home/CountSection";
import ThematicArea from "../sections/home/ThematicArea";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const bannerRef = useRef(null);
  const backgroundRef = useRef(null);

  useEffect(() => {
    // Parallax effect for the banner background image
    if (backgroundRef.current && bannerRef.current) {
      gsap.to(backgroundRef.current, {
        yPercent: -10, // Faster upward scroll for the background
        ease: "none",
        scrollTrigger: {
          trigger: bannerRef.current,
          start: "top top",
          end: "bottom top",
          scrub: true, // Smooth scrolling sync
        },
      });
    }
  }, []);

  return (
    <div>
      <div
        ref={bannerRef}
        className="relative min-h-[calc(100vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          ref={backgroundRef}
          className="absolute inset-0 bg-homebanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Empowering youth"
          subTitle="To build the future"
          paragraph="Transforming Lives Through the Education, Empowerment, and Opportunity to expose the youth potential on the sustainable development."
        />
      </div>

      {/* <HomeService /> */}
      {/* <WelcomeToSWI /> */}
      <CountSection />
      <ThematicArea />
      {/* <TrainingHome /> */}
      {/* <DonateSection /> */}
      {/* <ProgramsHome /> */}
      {/* <HomeBlog /> */}
    </div>
  );
};

export default Home;
