import { SocialLinks } from "./SocialLink";

export function ProfileCard({ name, role, photo, showSocial = false }) {
  return (
    <div className="relative aspect-[4/5] overflow-hidden rounded-[2rem] ">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${photo})` }}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#048642]/30 to-[#048642]/80" />
      <div className="absolute bottom-4 left-[0%] p-4 text-white">
        <h2 className="text-xl font-bold">{name}</h2>
        <p className="text-sm">{role}</p>
        {showSocial ? <SocialLinks className="mt-2" /> : null}
      </div>
    </div>
  );
}
