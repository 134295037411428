import { FaFacebookF, FaGlobe, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FiLinkedin } from "react-icons/fi";

export function SocialLinks({
  variant = "default",
  className = "",
  social_media,
}) {
  const socialLinks = [
    {
      label: <FaFacebookF color="#048642" />,
      href: social_media?.["facebook"],
    },
    {
      label: <FaInstagram color="#048642" />,
      href: social_media?.["instagram"],
    },
    { label: <FaXTwitter color="#048642" />, href: social_media?.["twitter"] },
    { label: <FiLinkedin color="#048642" />, href: social_media?.["linkedin"] },
    { label: <FaGlobe color="#048642" />, href: social_media?.["website"] },
  ];

  return (
    <div className={`flex gap-1 ${className}`}>
      {socialLinks.map((link) => (
        <button
          key={link.label}
          variant={variant}
          size="sm"
          className={`h-6 min-w-[32px] rounded-full px-2 text-xs font-semibold flex justify-center items-center ${
            variant === "default" ? "bg-white" : "hover:bg-white/10"
          }`}
          asChild
        >
          <a
            target="__blank"
            referrerPolicy="no-referrer"
            href={link.href}
            aria-label={link.label}
          >
            {link.label}
          </a>
        </button>
      ))}
    </div>
  );
}
