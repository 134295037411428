import * as cheerio from "cheerio";
import moment from "moment";
import React from "react";
import { CgCalendar } from "react-icons/cg";
import { Link } from "react-router-dom";

const TrainingCard = ({ data }) => {
  const desc = cheerio.load(data?.description)?.text();

  const calculatePercentage = () => {
    return parseFloat(
      ((data?.participants_count * 100) / data?.occupancy || 0).toFixed(0)
    );
  };

  return (
    <div className="bg-transparent rounded-3xl group shadow-md overflow-hidden relative cursor-pointer">
      {/* Background decoration */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-32 h-32 bg-yellow-100 rounded-full opacity-40 transform translate-x-[-50%] translate-y-[-50%]"></div>
        <div className="absolute bottom-0 right-0 w-40 h-40 bg-yellow-100 rounded-full opacity-40 transform translate-x-[50%] translate-y-[50%]"></div>
      </div>

      {/* Image Section */}
      <img
        src={data?.featured_img}
        alt="Card Background"
        className="w-full h-64 rounded-3xl object-cover grayscale group-hover:grayscale-0 duration-150"
      />

      {/* Content Section */}
      <div className="p-5 relative z-10">
        {/* Date and Tag */}
        <div className="flex items-center text-gray-500 space-x-3 text-sm">
          <div className="flex items-center space-x-1">
            <CgCalendar size={15} />
            <span>
              {moment(data?.date).format("MMMM D, YYYY")}{" "}
              {moment(data?.time, "HH:mm:ss").format("hh:mm A")} -{" "}
              {moment(data?.end_date).format("MMMM D, YYYY")}
            </span>{" "}
          </div>
          <div className="flex items-center space-x-1">
            <span>•</span>
            <span className="text-green-600 font-medium">
              {data?.category?.name}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <h3
            className="font-semibold text-lg mb-2 text-start line-clamp-2"
            style={{
              lineHeight: 1.5,
              height: "3rem",
            }}
          >
            {data?.name}
          </h3>
          <h3
            className="mb-2 text-start line-clamp-3"
            style={{
              lineHeight: 1.5,
              height: "4.5rem",
            }}
          >
            {desc}
          </h3>

          {/* Occupancy Percentage */}
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
            <div
              className={`h-2.5 rounded-full ${
                calculatePercentage() >= 75
                  ? "bg-red-500"
                  : calculatePercentage() >= 50
                  ? "bg-yellow-500"
                  : "bg-green-500"
              }`}
              style={{ width: `${calculatePercentage()}%` }}
            ></div>
          </div>
          <div className="text-sm text-gray-600 mb-4">
            {data?.participants_count} / {data?.occupancy} Spots Filled (
            {calculatePercentage()}%)
          </div>

          <Link to={`/programs/trainings/${data?.slug}`}>
            <button
              className={`mt-4 w-full py-2 rounded-md text-white font-medium bg-primary hover:opacity-90`}
            >
              View Details
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrainingCard;
