import React from "react";
import PageHeader from "../../components/PageHeader";

const Excursion = () => {
  return (
    <>
      <div
        // ref={bannerRef}
        className="relative min-h-[calc(70vh-7rem)] overflow-hidden bg-lightBg bg-cover bg-center"
      >
        <div
          // ref={backgroundRef}
          className="absolute inset-0 bg-aboutbanner bg-center bg-cover bg-no-repeat"
        />
        {/* Black overlay with gradient */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 75%)",
          }}
        />
        <PageHeader
          title="Excursion"
          breadcrumbs={[
            {
              name: "Home",
              to: "/",
            },

            {
              name: "Excursion",
              to: "/services/excursion",
            },
          ]}
        />
      </div>

      <div className="bg-lightBg relative overflow-hidden flex flex-col justify-center py-20">
        <div className="lg-container">
          <h1 class="text-3xl font-bold text-start mb-2">
            Give Children a Good Education and a Better Life
          </h1>
          <p class="text-lg mb-6">
            The SWI Training Center serves as a dynamic hub for empowering youth
            and fostering community development in the Kathmandu region and
            beyond. With a commitment to experiential field learning and
            knowledge exchange, the center offers a range of field programs,
            training practices, and excursion visits tailored to the needs of
            both local communities and external clients. Let's delve into the
            details of how SWI facilitates these opportunities for practical
            training and learning exchange:
          </p>

          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">
              1. Field Programs for Training Practice
            </h2>
            <p class="mb-4">
              The SWI Training Center organizes field programs that provide
              hands-on training opportunities for youth and aspiring development
              practitioners. These programs are designed to immerse participants
              in real-world scenarios and practical experiences relevant to
              community development, agriculture, entrepreneurship, and other
              key areas.
            </p>
            <ul class="list-disc list-inside space-y-2 mb-4">
              <li>
                <strong>Location Flexibility:</strong> SWI’s project area in
                Kathmandu serves as a primary location for field programs,
                allowing participants to engage directly with local communities
                and projects supported by SWI. Additionally, the center is
                equipped to organize field programs in other areas requested by
                clients, ensuring that training opportunities are accessible and
                tailored to specific needs and contexts.
              </li>
              <li>
                <strong>Expert Guidance:</strong> Experienced trainers and
                development professionals lead field programs, providing
                participants with expert guidance, mentorship, and support
                throughout their learning journey. Whether it's conducting needs
                assessments, implementing sustainable development projects, or
                facilitating community engagement activities, SWI ensures that
                participants receive practical training that aligns with
                industry best practices and local realities.
              </li>
            </ul>
          </div>

          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">
              2. Excursion Visits for Learning Exchange
            </h2>
            <p class="mb-4">
              In addition to field programs, the SWI Training Center offers
              excursion visits that facilitate learning exchange opportunities
              for development workers, students, and professionals. These visits
              provide participants with firsthand exposure to innovative
              projects, best practices, and success stories in community
              development and social entrepreneurship.
            </p>
            <ul class="list-disc list-inside space-y-2 mb-4">
              <li>
                <strong>Learning Opportunities:</strong> Excursion visits may
                include tours of SWI-supported projects, visits to local
                communities and organizations, and meetings with grassroots
                leaders and change agents. Participants have the opportunity to
                observe and learn from the practical experiences of local
                stakeholders, gaining valuable insights into the challenges and
                opportunities faced by communities in Kathmandu and surrounding
                areas.
              </li>
              <li>
                <strong>Networking and Collaboration:</strong> Excursion visits
                also foster networking and collaboration among participants,
                enabling them to connect with like-minded individuals, share
                knowledge and experiences, and explore potential partnerships
                for future initiatives. By facilitating interaction and dialogue
                among diverse stakeholders, SWI promotes cross-sectoral
                collaboration and collective action for positive social change.
              </li>
            </ul>
          </div>

          <div>
            <h2 class="text-2xl font-semibold mb-4">
              3. Knowledge Exchange Between Farmers and Development
              Practitioners
            </h2>
            <p class="mb-4">
              SWI serves as a catalyst for knowledge exchange between farmers,
              development practitioners, and other stakeholders involved in
              rural development and agriculture. The center facilitates
              collaborative learning platforms and initiatives that promote the
              sharing of expertise, resources, and innovative solutions to
              enhance agricultural productivity, sustainability, and resilience.
            </p>
            <ul class="list-disc list-inside space-y-2 mb-4">
              <li>
                <strong>Farmers' Forums:</strong> SWI organizes farmers’ forums,
                workshops, and field days where farmers can exchange knowledge,
                learn about new agricultural techniques and technologies, and
                access training and extension services. These forums provide a
                platform for farmers to share their traditional wisdom and
                practices while also learning from the experiences and expertise
                of development practitioners and agricultural experts.
              </li>
              <li>
                <strong>Participatory Learning Approaches:</strong> SWI adopts
                participatory learning approaches that prioritize the voices and
                perspectives of farmers and local communities. Development
                practitioners engage in dialogue with farmers to understand
                their needs, challenges, and aspirations, co-designing
                interventions and solutions that are contextually appropriate
                and sustainable.
              </li>
              <li>
                <strong>Capacity Building:</strong> SWI offers capacity-building
                programs and technical assistance to both farmers and
                development practitioners, equipping them with the skills,
                knowledge, and resources needed to improve agricultural
                practices, enhance livelihoods, and promote food security. By
                investing in the capacity of local actors, SWI contributes to
                building resilient and self-reliant communities capable of
                addressing the challenges of a rapidly changing world.
              </li>
            </ul>
            <p class="mb-4">
              In summary, the SWI Training Center plays a pivotal role in
              facilitating practical training, learning exchange, and knowledge
              sharing in development initiatives that empower youth, strengthen
              communities, and promote sustainable development in Kathmandu and
              beyond. Through field programs, excursion visits, and
              collaborative platforms for knowledge exchange, SWI strives to
              build the capacity of individuals and organizations to create
              positive change and contribute to the well-being of society.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Excursion;
